<template>
  <v-container class="pa-0">
    <v-card
      v-if="piece.ptype === 'HEADER'"
      tile
      outlined
      shaped
      class="ma-0 pa-0"
      style="background-color: white"
      height="40px"
      ><v-card-title class="mx-0 px-2 py-1">{{ piece.value }}</v-card-title>
    </v-card>

    <template
      v-if="piece.ptype === 'TEXT'"
      class="ma-0 pa-0"
      style="background-color: white"
    >
      <div v-html="convertNewlineToBR(escapeHtml(piece.basetext))"></div>
      <div align="center" v-if="piece.imgfile">
        <v-img
          v-bind:src="piece.imgurl"
          v-bind:height="getCoefficientHeight(piece.imgwidth, piece.imgheight)"
          v-bind:width="getCoefficientWidth(piece.imgwidth)"
        ></v-img>
      </div>
    </template>

    <div
      v-if="piece.ptype === 'HTML'"
      class="ma-0 pa-0"
      style="background-color: white"
      v-html="piece.value"
    ></div>

    <v-chip
      v-if="piece.ptype === 'LINK' && piece.href"
      v-bind:href="piece.href"
      v-bind:target="piece.target"
      label
      style="text-decoration: none; color: black"
      >{{ piece.label }}</v-chip
    >
    <v-chip
      v-if="piece.ptype === 'LINK' && piece.to"
      v-bind:to="addModeStringToPath(piece.to)"
      label
      style="text-decoration: none; color: black"
      >{{ piece.label }}</v-chip
    >

    <div align="center">
      <v-img
        v-if="piece.ptype === 'IMAGE'"
        v-bind:src="piece.imgurl"
        v-bind:height="getCoefficientHeight(piece.imgwidth, piece.imgheight)"
        v-bind:width="getCoefficientWidth(piece.imgwidth)"
      ></v-img>
    </div>

    <div align="center">
      <v-progress-linear
        v-if="piece.ptype === 'SPLITTER'"
        color="teal"
        v-bind:indeterminate="this.isIndeterminate(piece.seq)"
      ></v-progress-linear>
    </div>

    <!-- CHOICE 型問題の選択肢 start -->
    <v-radio-group
      v-model="piece.placedanswer"
      v-if="piece.ptype === 'CHOICE'"
      class="mt-n3 pa-0"
    >
      <v-row v-if="piece.lead"
        ><v-col>
          <div
            class="ma-0 pa-0"
            v-bind:style="selectTcs('standardtext')"
            v-html="convertNewlineToBR(escapeHtml(piece.lead))"
          ></div> </v-col
      ></v-row>
      <v-row v-if="piece.body1">
        <v-spacer></v-spacer>
        <v-col cols="10">
          <div
            class="ma-0 pa-2"
            v-bind:style="selectTcs('choicebody1')"
            v-html="convertNewlineToBR(escapeHtml(piece.body1))"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row v-if="piece.imgurl"
        ><v-col align="center">
          <v-img
            v-bind:src="piece.imgurl"
            v-bind:height="
              getCoefficientHeight(piece.imgwidth, piece.imgheight)
            "
            v-bind:width="getCoefficientWidth(piece.imgwidth)"
          ></v-img> </v-col
      ></v-row>
      <v-row
        ><v-col><v-spacer></v-spacer></v-col
      ></v-row>
      <div v-for="(optitem, index) in piece.optarray" :key="index">
        <v-row>
          <v-col cols="2">
            <v-chip
              min-height="35"
              v-if="optitem.isCorrectAnswer && piece.isAnswered"
              color="blue lighten-4"
              >正解
            </v-chip>
          </v-col>
          <v-col cols="10">
            <v-radio v-bind:value="index + 1" align="center">
              <template v-slot:label>
                <div v-bind:style="selectTcs('standardtext')">
                  {{ optitem.basetext }}
                </div>
              </template>
            </v-radio>
            <div v-if="Boolean(optitem.imgfile)">
              <v-card v-on:click="gvars.placedanswer = index + 1">
                <v-img
                  v-bind:src="getImageUrlCommonMethod(optitem.imgfile)"
                  v-bind:height="
                    getCoefficientHeight(optitem.imgwidth, optitem.imgheight)
                  "
                  v-bind:width="getCoefficientWidth(optitem.imgwidth)"
                ></v-img>
              </v-card>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row height="20"><v-col></v-col></v-row>
      </div>
      <v-row v-if="piece.body2">
        <v-spacer></v-spacer>
        <v-col cols="10">
          <div
            class="ma-0 pa-2"
            v-bind:style="selectTcs('choicebody2')"
            v-html="convertNewlineToBR(escapeHtml(piece.body2))"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-radio-group>
    <!-- CHOICE 型問題の選択肢 end -->

    <!-- TEXTFIELD 型問題の選択肢 start -->
    <div v-if="piece.ptype === 'TEXTFIELD'">
      <v-row v-if="piece.lead"
        ><v-col>
          <div
            class="ma-0 pa-0"
            v-bind:style="selectTcs('standardtext')"
            v-html="convertNewlineToBR(escapeHtml(piece.lead))"
          ></div> </v-col
      ></v-row>
      <v-row v-if="piece.body1">
        <v-spacer></v-spacer>
        <v-col cols="10">
          <div
            class="ma-0 pa-2"
            v-bind:style="selectTcs('choicebody1')"
            v-html="convertNewlineToBR(escapeHtml(piece.body1))"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row v-if="piece.imgurl"
        ><v-col align="center">
          <v-img
            v-bind:src="piece.imgurl"
            v-bind:height="
              getCoefficientHeight(piece.imgwidth, piece.imgheight)
            "
            v-bind:width="getCoefficientWidth(piece.imgwidth)"
          ></v-img> </v-col
      ></v-row>
      <v-row
        ><v-col><v-spacer></v-spacer></v-col
      ></v-row>
      <div v-for="(optitem, index) in piece.optarray" :key="index">
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="10">
            <v-text-field
              v-bind:prefix="optitem.fieldname"
              v-bind:hint="optitem.fieldcaption"
              v-model="optitem.fieldvalue"
              outlined
              prepend-icon="mdi-pencil"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      <v-row v-if="piece.body2">
        <v-spacer></v-spacer>
        <v-col cols="10">
          <div
            class="ma-0 pa-2"
            v-bind:style="selectTcs('choicebody2')"
            v-html="convertNewlineToBR(escapeHtml(piece.body2))"
          ></div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>

    <!-- TEXTFIELDS 型問題の選択肢 end -->

    <v-row
      v-if="
        piece.ptype === 'MORE' ||
        piece.ptype === 'ANSWER' ||
        piece.ptype === 'FINISH'
      "
    >
      <v-col cols="2"></v-col>
      <v-col cols="8">
        <v-btn
          v-if="piece.ptype === 'MORE'"
          height="40px"
          color="info"
          v-bind:disabled="gvars.btndisabled"
          v-on:click="triggerExecuteMoreBtn(piece.seq)"
          style="float: left"
          >{{ piece.label }}
        </v-btn>
        <v-btn
          v-if="piece.ptype === 'ANSWER'"
          height="40px"
          color="info"
          v-bind:disabled="gvars.btndisabled"
          v-on:click="triggerExecuteAnswerBtn(piece.seq)"
          style="float: left"
          >{{ piece.label }}
        </v-btn>
        <v-btn
          v-if="piece.ptype === 'FINISH'"
          height="40px"
          color="info"
          v-bind:disabled="piece.disabled"
          v-on:click="triggerExecuteFinishBtn(piece.seq)"
          style="float: right"
          >{{ piece.label }}
        </v-btn>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row v-if="piece.ptype === 'PROCEED'">
      <v-col cols="5"></v-col>
      <v-col cols="2">
        <v-btn
          height="40px"
          color="info"
          v-bind:disabled="piece.disabled"
          v-on:click="triggerExecuteProceedBtn(piece.seq)"
          >{{ piece.label }}
        </v-btn>
      </v-col>
      <v-col cols="5"></v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import Vue from 'vue';
import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};


export default Vue.extend({
  name: 'PieceBlock',

  mixins:[cm],

  data: () => ({
  }),


  props:{
    piece:{
        type: Object,
        required:true,
    },
    executeMoreBtnEmitter:{
        type: Function,
        required:false,
    },
    executeProceedBtnEmitter:{
        type: Function,
        required:false,
    },
    executeFinishBtnEmitter:{
        type: Function,
        required:false,
    },
    executeAnswerBtnEmitter:{
        type: Function,
        required:false,
    },
  },

  computed:{
  },


  created: function(){
    debugLog('PieceBlock Created:', this.piece);
    this.gvars=this.$store.getters['vars/gv'];
//    this.piece = this.gvars.frames[this.fidx].pieces[this.pidx];
//    debugLog(`${this.piece.value}`);
  },

  mounted: function(){
    debugLog('PieceBlock Mounted');
    debugLog(`PieceBlock Mounted ${this.piece.value} ${this.piece.visible}` );
  },

  methods:{
    isRightAnswer:function(piece,optindex){
      debugLog("placedanswer:", piece.placedanswer);
      debugLog("rightanswer:",piece.rightanswer);
      debugLog("optindex:",optindex);
      return (piece.rightanswer == optindex +1);
    },

    triggerExecuteMoreBtn:function(idx){
      debugLog("triggerExecuteMoreBtn:",idx);
      this.executeMoreBtnEmitter(idx);

    },

    triggerExecuteProceedBtn:function(idx){
      debugLog("triggerExecuteProceedBtn:");
      this.executeProceedBtnEmitter(idx);

    },

    triggerExecuteAnswerBtn:function(idx){
      debugLog("triggerExecuteAnswerBtn:",idx);
      this.executeAnswerBtnEmitter(idx);

    },

    triggerExecuteFinishBtn:function(idx){
      debugLog("triggerExecuteFinishBtn:");
      this.executeFinishBtnEmitter(idx);

    },

    isHref:function(piece){
      const v=JSON.parse(piece.value);
      return piece.ptype==="link" && 'href' in v;
    },

    isTo:function(piece){
      const v=JSON.parse(piece.value);
      return piece.ptype==="link" && 'to' in v;
    },

    href:function(piece){
      const v=JSON.parse(piece.value);
      return v.href;
    },

    to:function(piece){
      const v=JSON.parse(piece.value);
      return v.to;
    },

    target:function(piece){
      const v=JSON.parse(piece.value);
      return v.target;
    },

    text:function(piece){
      const v=JSON.parse(piece.value);
      return v.text;
    },

    getImageParametersCaller:async function(piece){
      return await this.getImageParameters(piece);
    },

    getImageParameters:async function(piece){
      const imgurl = this.getImageUrlCommonMethod(piece.value)
      const res = await this.loadImageSizeCommon(imgurl);
      piece.imgwidth=res.width;
      piece.imgheight=res.height;

      debugLog(imgurl);
      debugLog(res.width,res.height);
      return imgurl;
    },

    objectLog:function(obj){
      debugLog("objectLog",obj);
      return "Logged";
    },

    exists:function(){
      return true;
//      return str?true:false;
    },

  },

})
</script>
