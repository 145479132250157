<template>
  <v-container class="pa-0">
    <v-row v-if="blocklabel" style="height: 30px"
      ><v-col>{{ blocklabel }}</v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card v-bind="selectproperty(propertyselector)">
          <v-card-text
            v-bind:style="selectTcs('standardtext')"
            v-html="convertNewlineToBR(textimage.basetext)"
          ></v-card-text>
          <v-img
            v-if="Boolean(textimage.imgfile)"
            v-bind:src="getImageUrlCommonMethod(textimage.imgfile)"
            v-bind:height="
              getCoefficientHeight(textimage.imgwidth, textimage.imgheight)
            "
            v-bind:width="getCoefficientWidth(textimage.imgwidth)"
          ></v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import Vue from 'vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};


const propertiesdefault='outlined';
const properties={
  outlined:{
    outlined:true,
  },
  flat:{
    flat:true,
  },

}

export default Vue.extend({
  name: 'TextImageBlock',
  props:['textimage','blocklabel','propertyselector'],

  mixins:[cm],

  mounted: function(){
    debugLog("TextImageBlock textimage", this.textimage);
  },

  methods: {

    selectproperty: function(propertyname){
      propertyname= properties?propertyname:propertiesdefault;
      return properties[propertyname];
    },
  }

})
</script>
