import Vue from 'vue';
import VueRouter from 'vue-router';
import ControllerPage from '@/views/ControllerPage.vue';
import ScenarioController from '@/views/ScenarioController.vue';
import About from '@/views/About.vue';
import Mailform from '@/views/Mailform.vue';
import Toppage from '@/views/Toppage.vue';
import Notify1page from '@/views/Notify1page.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/gsc/:code?/:mode?',
    name: 'Scenario',
    props: true,
    component: ScenarioController,
    meta: {
      title: 'ロジック図解講座',
      desc:
        '複雑な情報を整理して図解するトレーニングができる練習問題を掲載しています',
    },
  },
  {
    path: '/gst/:code?/:mode?',
    name: 'TestingWithMode',
    props: true,
    component: ControllerPage,
    meta: {
      title: '問題チャレンジ',
      desc:
        '複雑な情報を整理して図解するトレーニングができる練習問題を掲載しています',
    },
  },
  {
    path: '/contact/:mode?',
    name: 'Mailform',
    props: true,
    component: Mailform,
    meta: {
      title: 'ご連絡フォーム',
      desc: 'ご連絡フォーム',
    },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'About',
      desc:
        '複雑な情報を整理して図解するトレーニングができる練習問題を掲載しています',
    },
  },
  {
    path: '/notify1/:mode?',
    name: 'Notify1page',
    props: true,
    component: Notify1page,
    meta: {
      title: 'お知らせ',
      desc: 'お知らせ',
    },
  },
  {
    path: '/:mode?',
    name: 'Toppage',
    props: true,
    component: Toppage,
    meta: {
      title: 'トップページ',
      desc: 'トップページ',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
