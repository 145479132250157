var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.gvars.activePage === 'COMMENTARY')?_c('v-container',[_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"v-text-field-low",attrs:{"name":"qc_title","prefix":"問題名:","color":"cyan lighten-4","readonly":""},model:{value:(_vm.gvars.currentquestion.title),callback:function ($$v) {_vm.$set(_vm.gvars.currentquestion, "title", $$v)},expression:"gvars.currentquestion.title"}})],1)],1),_c('commentary-block',{attrs:{"commentaryitems":this.gvars.currentquestion.commentaryitems,"blocklabel":'解説'}}),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"info","width":"200px"},on:{"click":_vm.triggerCloseCommentary}},[_vm._v("前ページへ戻る")])],1)],1),_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',{staticClass:"ma-2 py-1",staticStyle:{"background-color":"#00897b","color":"white"},attrs:{"cols":"12","align":"center"}},[_vm._v(" （以下、出題を再掲） ")])],1),_c('v-row',[_c('v-col')],1),(_vm.isText1Exists())?_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.convertNewlineToBR(_vm.gvars.currentquestion.text1obj.basetext))}})],1),(Boolean(_vm.gvars.currentquestion.text1obj.imgfile))?_c('div',[_c('v-card',[_c('v-img',{attrs:{"src":_vm.getImageUrlCommonMethod(_vm.gvars.currentquestion.text1obj.imgfile),"height":_vm.getCoefficientHeight(
                _vm.gvars.currentquestion.text1obj.imgwidth,
                _vm.gvars.currentquestion.text1obj.imgheight
              ),"width":_vm.getCoefficientWidth(_vm.gvars.currentquestion.text1obj.imgwidth)}})],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mx-2 mt-2 mb-0 py-1 px-3",staticStyle:{"background-color":"#e0e0e0"}},[_vm._v(" 設問 ")])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',{domProps:{"innerHTML":_vm._s(_vm.convertNewlineToBR(_vm.gvars.currentquestion.text2obj.basetext))}}),(Boolean(_vm.gvars.currentquestion.text2obj.imgfile))?_c('v-img',{attrs:{"src":_vm.getImageUrlCommonMethod(_vm.gvars.currentquestion.text2obj.imgfile),"height":_vm.getCoefficientHeight(
              _vm.gvars.currentquestion.text2obj.imgwidth,
              _vm.gvars.currentquestion.text2obj.imgheight
            ),"width":_vm.getCoefficientWidth(_vm.gvars.currentquestion.text2obj.imgwidth)}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col')],1),(_vm.isText3Exists())?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mx-2 mt-2 mb-0 py-1 px-3",staticStyle:{"background-color":"#e0e0e0"}},[_vm._v(" 課題文 ")])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',{domProps:{"innerHTML":_vm._s(
              _vm.convertNewlineToBR(_vm.gvars.currentquestion.text3obj.basetext)
            )}}),(Boolean(_vm.gvars.currentquestion.text3obj.imgfile))?_c('v-img',{attrs:{"src":_vm.getImageUrlCommonMethod(_vm.gvars.currentquestion.text3obj.imgfile),"height":_vm.getCoefficientHeight(
                _vm.gvars.currentquestion.text3obj.imgwidth,
                _vm.gvars.currentquestion.text3obj.imgheight
              ),"width":_vm.getCoefficientWidth(_vm.gvars.currentquestion.text3obj.imgwidth)}}):_vm._e()],1)],1)],1)],1):_vm._e(),(this.gvars.currentquestion.ansmethod == 'TEXTFIELD')?_c('div',[_c('v-row',[_c('v-col')],1),_vm._l((this.gvars.currentquestion.optarray),function(optitem,index){return _c('div',{key:index},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"value":optitem.fieldanswer,"prefix":optitem.fieldname,"readonly":""}})],1),_c('v-spacer')],1),_c('v-row',{attrs:{"height":"20"}},[_c('v-col')],1)],1)})],2):_vm._e(),(this.gvars.currentquestion.ansmethod == 'CHOICE')?_c('div',[_c('v-row',[_c('v-col')],1),_c('v-row',[_c('v-col',{staticClass:"ma-2 py-1 px-3",staticStyle:{"background-color":"#e0e0e0"}},[_vm._v(" 選択肢 ")])],1),_vm._l((this.gvars.currentquestion.optarray),function(optitem,index){return _c('div',{key:index},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[(_vm.isRightAnswer(index))?_c('v-chip',{attrs:{"min-height":"35","color":"blue lighten-4"}},[_vm._v("正解 ")]):_vm._e()],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-card',{staticClass:"py-2 pl-2 ma-0",attrs:{"min-height":"35","color":_vm.getRightColor(index)}},[_vm._v(_vm._s(optitem.basetext)+" "),(Boolean(optitem.imgfile))?_c('div',[_c('v-img',{attrs:{"src":_vm.getImageUrlCommonMethod(optitem.imgfile),"height":_vm.getCoefficientHeight(optitem.imgwidth, optitem.imgheight),"width":_vm.getCoefficientWidth(optitem.imgwidth)}})],1):_vm._e()])],1),_c('v-spacer')],1),_c('v-row',{attrs:{"height":"20"}},[_c('v-col')],1)],1)})],2):_vm._e(),(_vm.isText4Exists())?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mx-2 mt-2 mb-0 py-1 px-3",staticStyle:{"background-color":"#e0e0e0"}},[_vm._v(" ヒント ")])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',{domProps:{"innerHTML":_vm._s(
              _vm.convertNewlineToBR(_vm.gvars.currentquestion.text4obj.basetext)
            )}}),(Boolean(_vm.gvars.currentquestion.text4obj.imgfile))?_c('v-img',{attrs:{"src":_vm.getImageUrlCommonMethod(_vm.gvars.currentquestion.text4obj.imgfile),"height":_vm.getCoefficientHeight(
                _vm.gvars.currentquestion.text4obj.imgwidth,
                _vm.gvars.currentquestion.text4obj.imgheight
              ),"width":_vm.getCoefficientWidth(_vm.gvars.currentquestion.text4obj.imgwidth)}}):_vm._e()],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{attrs:{"color":"info","width":"200px"},on:{"click":_vm.triggerCloseCommentary}},[_vm._v("前ページへ戻る")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }