const state = {
  serverinfo: '',
};

const getters = {
  serverinfo: (state) => state.serverinfo,
};

const actions = {
  setServerinfo({ commit }, serverinfo) {
    commit('setServerinfo', serverinfo);
  },
};

const mutations = {
  setServerinfo(state, serverinfo) {
    console.log('setServerinfo:' + serverinfo);
    state.serverinfo = serverinfo;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
