<template>
  <div ref="controllerPageTop">
    <!-- ------------- 開始案内ページ ------------- -->
    <scenario-header-panel
      v-if="this.gvars.activePage === 'SCHEADER'"
      :start-scenario-execution-emitter="startScenarioExecution"
    />

    <div v-if="isFramesExists() && reloadflag">
      <div
        v-for="(piece, index) in this.gvars.frames[this.gvars.fridx].pieces"
        :key="index"
      >
        <v-row>
          <v-col class="ma-0 px-6 py-3">
            <piece-block
              v-if="gvars.frames[gvars.fridx].pieces[index].visible"
              :piece="gvars.frames[gvars.fridx].pieces[index]"
              :execute-more-btn-emitter="executeMoreBtn"
              :execute-proceed-btn-emitter="executeProceedBtn"
              :execute-finish-btn-emitter="executeFinishBtn"
              :execute-answer-btn-emitter="executeAnswerBtn"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- ------------- systemMessage ------------- -->
    <div v-if="this.gvars.scenarioFinished">
      <ask-impression-block></ask-impression-block>
    </div>
    <system-message :systemMessage="this.gvars.systemMessage" />
    <div v-if="false">
      <!-- ------------- 区切り線 ------------- -->
      <v-row style="background-color: #00897b; height: 10px" class="my-10"
        ><v-col></v-col
      ></v-row>
      <!---         権利表記等           ---------->
      <v-row>
        <v-card>
          <v-card-text
            class="text-caption"
            v-bind:style="selectTcs('standardtext')"
            ><P
              >当サイトは<A href="https://ideacraft.jp" target="_blank"
                >アイデアクラフト</A
              >が運営しています。</P
            >
            <p>
              当サイト上のすべてのコンテンツの著作権は、開米瑞浩が保持しています。（別途著作権表示のあるものを除く）。
            </p>
            <p>
              コンテンツ改善を目的とする統計処理のため、個人情報を含まない解答データを収集しています。
            </p>
            <p>
              感想を送信する最終ページに個人情報（氏名・Email）の欄がありますが入力は任意です。
            </p>
            <p>
              御入力いただいた個人情報は当コンテンツに関する質問への回答にのみ利用し、他の用途には使用しません。
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

// scriptタグ内にスクリプト
<script lang="js">
import Vue from 'vue';

import { DateTime } from "luxon";

import {getRecords} from '../client/client.js';
import {postRecord} from '../client/client.js';


import ScenarioHeaderPanel from '@/components/ScenarioHeaderPanel.vue';
import SystemMessage from '@/components/SystemMessage.vue';
import PieceBlock from '@/components/PieceBlock.vue';
import AskImpressionBlock from '@/components/AskImpressionBlock.vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};



export default Vue.extend({
  name: 'ScenarioController',

  mixins:[cm],

  components:{
    SystemMessage,
    ScenarioHeaderPanel,
    PieceBlock,
    AskImpressionBlock,
  },


  props:{
    code:{
      type:String,
      required: false,
      default:''
    },
    mode:{
      type:String,
      required: false,
      default:'',
    },
  },

  data: () => ({
    reloadflag:true,
    visibilityInitializer:false,
  }),


  created: function(){
    debugLog('ScenarioController Created');
    this.initializeStaticParameters(this.mode);
    this.gvars=this.$store.getters['vars/gv'];
    this.gvars.activePage = 'NONE';
    this.gvars.scenarioFinished=false; // systemMessageのブロックでcannot read property が出るのを防ぐ

  },

  mounted: async function(){
    debugLog('ScenarioController mounted');
    debugLog('code:', this.code);
    debugLog('mode:', this.mode);
    document.title = this.$route.meta.title + ' | ' + process.env.VUE_APP_SITENAME;
    debugLog(document.title);

    this.startScenarioExecution(); // /:code で指定されたシナリオを開始する
  },

  methods: {
    isFramesExists(){
      try{
        debugLog("is this.gvars.frames.length exists?");
        return this.gvars.frames.length>0;
      }
      catch(e){
        debugLog("this.gvars.frames.length isn't exists")
        return false;
      }
    },

    startScenarioTimer: function(){
      this.gvars.scgrandstarted = DateTime.now();
      this.gvars.sclapstarted = this.gvars.scgrandstarted ;
    },

    recordLapPoint: function(){
      const now = DateTime.now();
      debugLog("sclapstarted:",this.gvars.sclapstarted);
      const lapseconds =  Math.floor(now.diff(this.gvars.sclapstarted) /1000);
      this.gvars.sclapstarted = now;
      return lapseconds;
    },

    getScenarioUsedSeconds: function(){
      const now = DateTime.now();
      const usedseconds =  Math.floor(now.diff(this.gvars.scgrandstarted) /1000);
      return usedseconds;
    },

    startScenarioExecution: function(){
      this.clearData();
      this.beginScenarioSession(this.code);
    },

    clearData: function(){
      this.clearSystemMessage();
      this.clearTrialMessage();
      // this.setSystemMessage("システムメッセージ表示テスト");
      this.gvars.scenario=[];
      this.gvars.frames=[];
    },

    // Scenario データを取得
    beginScenarioSession: async function(code){
      debugLog('beginScenarioSession start');
      if(!code){
        debugLog('bad code');
        this.setSystemMessage("パラメータが間違っています");
        return;
      }

      debugLog('calling /gs/CSC/');
      const result = await getRecords('/gs/CSC/', code);
      if(result.resultcode != 'OK' || result.querystatus != 'FOUND'){
        debugLog(result);
        this.setSystemMessage("データを取得できません");
        return;
      }

      debugLog(result);
      const record = result.rows[0];

      this.gvars.scenarioFinished=false;
      this.gvars.scenario = record
      this.gvars.frames = JSON.parse(record.frames);
      debugLog("---------------frames---------------");
      debugLog(this.gvars.frames);
      debugLog("---------------frames[0]---------------");
      debugLog(this.gvars.frames[0]);
      debugLog("---------------frames[0].pieces---------------");
      debugLog(this.gvars.frames[0].pieces);
      this.gvars.frame = this.gvars.frames[0];
      this.gvars.fridx = 0; // 現在表示しているframeの番号
      this.gvars.frcnt = this.gvars.frames.length;
      this.gvars.pieces = this.gvars.frames[0].pieces;

      // piece パラーメー他の読みとり
      for(let j=0; j<this.gvars.frcnt; j++){
        this.visibilityInitializer = true;
        for(let i=0; i<this.gvars.frames[j].pieces.length; i++ ){
          let p = this.gvars.frames[j].pieces[i];
          p.seq = i;  // piece に連番を付与して more-visibility のコントロールに使う

          this.setVisibilities(p);
          this.analyseLinkPieceParameters(p);
          this.analyseButtonPieceParameters(p);
          await this.analyseTextPieceParameters(p);
          await this.analyseImagePieceParameters(p);
          await this.analyseQuestionPieceParameters(p);


        }
      }

      this.startScenarioTimer();
      this.switch2ScenarioHeader();
      this.doForceUpdate();
      debugLog("beginScenarioSession end");
    },

    // forceUpdateをかける
    doForceUpdate:function(){
      this.reloadflag=false;
      this.$forceUpdate();
      this.$nextTick(function(){
        this.reloadflag=true;
      });
    },

    // visibility のセット
    setVisibilities:function(p){
      p.visible = this.visibilityInitializer;
      if(p.ptype==="MORE" || p.ptype==="ANSWER")
        this.visibilityInitializer = false;   //MORE,ANSWER ボタンより後ろは visible を falseにする
      debugLog(`${p.ptype}, ${this.visibilityInitializer}`);
    },

    // SPLITTERはmore,answerボタンの次に配置する。pidxはボタンの次のpieceのidxを渡す。
    startSplitterTimer:function(pidx){
      const bpnext = this.gvars.frames[this.gvars.fridx].pieces[pidx]; // ボタンの 次のpiece
      debugLog("startSplitterTimer:", pidx, bpnext);
      if(bpnext.ptype==='SPLITTER'){
          debugLog("SPLITTER indeterminate=false;");
          this.setIndeterminate(bpnext.seq, true);
          bpnext.visible=true;
          setTimeout((i)=>{
            debugLog(this.gvars.frames[this.gvars.fridx].pieces[i]);
            this.setIndeterminate(bpnext.seq, false);
            this.gvars.frames[this.gvars.fridx].pieces[i].value=100;
            debugLog("SPLITTER timeout",this.gvars.fridx,i);
          },1000,pidx);
          this.doForceUpdate();
      }

    },



    executeMoreBtn: function(pidx){
      const bp = this.gvars.frames[this.gvars.fridx].pieces[pidx]; // ボタンの piece
      debugLog(bp);
      this.gvars.btndisabled=true;
      debugLog("executeMoreBtn",bp);
      this.startSplitterTimer(pidx+1);
      setTimeout(this.executeMoreBtnTimeout,1000,pidx);

    },

    executeMoreBtnTimeout: function(pidx){
      const pieces=this.gvars.frames[this.gvars.fridx].pieces;
      const quep = this.gvars.frames[this.gvars.fridx].pieces[pidx-1]; // 質問の piece
      const btnp = this.gvars.frames[this.gvars.fridx].pieces[pidx]; // ボタンの piece
      if(pieces[pidx].vanish) pieces[pidx].visible=false;
      debugLog(`pieces length = ${pieces.length}`);

      // 次に出てくる Button まで visible化する
      for( let i=pidx+1; i<pieces.length; i++){
        pieces[i].visible=true;
        debugLog(`${pieces[i].ptype}, ${pieces[i].visible} ${pieces[i].value}`);

        if( this.isButtonPiece(pieces[i].ptype) && i > pidx){
          break;
        }
      }

      this.gvars.btndisabled=false;
      const lapseconds = this.recordLapPoint();
      btnp.lapseconds = lapseconds;

      //1つ前が質問pieceだったときはその処理をする
      if(this.isInquiryPiece(quep.ptype)){
        quep.lapseconds = lapseconds; //質問にもlapseconds を設定しておく（btnと同じ値)
        if(quep.ptype==='CHOICE'){
          quep.donecorrectly = (quep.placedanswer == quep.CorrectAnswerNum);
          quep.isAnswered = true;
          debugLog("CHOICE value:", quep);
        }
        else if(quep.ptype==='TEXTFIELD'){
          debugLog("TEXTFIELD value:", quep);
        }
      }

      debugLog("記録送信",btnp, btnp.send);
      if(btnp.send){
        debugLog("calling sendScenarioResults");
        this.sendScenarioResults();
      }

      this.doForceUpdate();
      debugLog("More:",pieces[pidx].id,pieces[pidx].note);
    },

    executeProceedBtn: function(pidx){
      const btnp = this.gvars.frames[this.gvars.fridx].pieces[pidx];

      const lapseconds = this.recordLapPoint();
      btnp.lapseconds = lapseconds;

      debugLog("記録送信",btnp, btnp.send);
      if(btnp.send){
        debugLog("calling sendScenarioResults");
        this.sendScenarioResults();
      }

      if(this.gvars.fridx < this.gvars.frcnt -1){
        debugLog("Proceed")
        this.gvars.fridx ++;
        this.doForceUpdate();
      }
      else{
        this.gvars.frames[this.gvars.fridx].pieces[pidx].visible=false;
        debugLog("最終フレーム");
      }
      window.scrollTo(0,0);
      debugLog("Proceed:",btnp.id,btnp.note);
    },

    executeFinishBtn: function(pidx){
      const bp = this.gvars.frames[this.gvars.fridx].pieces[pidx]; // ボタンの piece
      this.gvars.scenarioFinished=true;
      bp.visible=false;

      this.doForceUpdate();
      debugLog("Finish:",bp);
    },

    executeAnswerBtn: function(pidx){
      const bp = this.gvars.frames[this.gvars.fridx].pieces[pidx]; // ボタンの piece
//      bp.visible=false;
      debugLog(bp);
      this.gvars.btndisabled=true;
      debugLog("executeAnswerBtn",bp);
      this.startSplitterTimer(pidx+1);
      setTimeout(this.executeAnswerBtnTimeout,1000,pidx);

    },

    executeAnswerBtnTimeout: function(pidx){
      const p = this.gvars.frames[this.gvars.fridx].pieces[pidx-1]; // 質問の piece
      const bp = this.gvars.frames[this.gvars.fridx].pieces[pidx]; // ボタンの piece
      this.gvars.btndisabled=false;

      const lapseconds = this.recordLapPoint();
      p.lapseconds = lapseconds;

      if(p.ptype==='CHOICE'){
        p.donecorrectly = (p.placedanswer == p.CorrectAnswerNum);
        p.isAnswered = true;
      }
      else if(p.ptype==='TEXTFIELD'){
        debugLog("TEXTFIELD value:", p);
      }

      debugLog("解答ボタン処理",bp, bp.send);
      if(bp.send){
        debugLog("calling sendScenarioResults");
        this.sendScenarioResults();
      }

      this.executeMoreBtnTimeout(pidx);
      debugLog("Answer", p.code);
    },


    // 解答データを送信する
    sendScenarioResults: async function(){

      debugLog("解答データ送信");

      const sresult=this.gvars.frames.map(this.arrangeSresultOfAFrame );
      let joined = [];
      debugLog("sresult:",sresult);
      sresult.forEach(function(a){joined=joined.concat([...a])});
      debugLog("sresult joined:",joined);
      const usedseconds = this.getScenarioUsedSeconds();
      const now = DateTime.now();
      const sresultParams={
        usedseconds:usedseconds,
        finishedtime:now.toFormat('yyyy-MM-dd TT'),
        sid:this.gvars.scenario.sid,
        code:this.gvars.scenario.code,
        sresult:joined
      };
      debugLog("sresultParams:", sresultParams);


      debugLog(`(${this.mode}) calling /gs/WSR/`);

      if(!this.$store.getters['mode/verifyonly']){
        const wsrresult = await postRecord('/gs/WSR/', sresultParams);
        debugLog(wsrresult);
      }

    },

    // frame オブジェクト内の pieces を sresultの形に構成したオブジェクトを返す
    arrangeSresultOfAFrame: function(frame){
      debugLog("単一フレーム内解答処理");
      debugLog(frame);
      const rps = frame.pieces.filter( function(p){
        debugLog("arrangeSresultOfAFrame ptype:", p.ptype);
        return this.isRecordingPiece(p.ptype);
      }.bind(this));
      debugLog("rps:", rps);

      const s_rps = rps.map(function(p){
        console.assert(this.isRecordingPiece(p.ptype),"Illegal piece found (not a recordingpieces)");
        if(p.ptype==='CHOICE'){
          return {
            ptype:p.ptype,
            code:p.code,
            placedanswer:p.placedanswer,
            donecorrectly:p.donecorrectly,
            lapseconds:p.lapseconds
            };
        }
        else if(p.ptype==='TEXTFIELD'){
          const textentries = {};
          p.optarray.forEach(element => {
            textentries[element.fieldname.trim()] = element.fieldvalue;
            });
          return {
            ptype:p.ptype,
            code:p.code,
            donecorrectly:'NA',
            lapseconds:p.lapseconds,
            textentries:textentries
            };
        }
        else if(p.ptype==='MORE' || p.ptype==='PROCEED'){
          return {
            ptype:p.ptype,
            id:p.id,
            note:p.note,
            lapseconds:p.lapseconds
            };
        }
      }.bind(this));
      debugLog("s_rps:",s_rps);

      return s_rps;

    },



    switch2ScenarioHeader: async function() {
      this.gvars.activePage = 'SCHEADER';
    },

  },
});
</script>
