<template>
  <v-container>
    <v-row>
      <v-col class="ma-0 pa-0"
        ><v-text-field
          class="v-text-field-low"
          solo
          dense
          filled
          flat
          value="結果一覧 (クリックで解説表示)"
          style="font-weight: bold"
          readonly
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row
      class="my-1 py-0"
      v-for="(quest, index) in this.gvars.questions"
      v-bind:key="index"
      v-on:click="triggerOpenCommentary(index)"
    >
      <v-col cols="5" sm="3" md="2" align="center" class="my-1 py-0 my-sm-2">
        <v-chip min-height="35" label color="blue lighten-4"
          ><span style="padding: 2px 2px">{{ quest.rightanswer }}</span>
        </v-chip>
        <span style="padding: 5px 5px">-</span>
        <v-chip
          min-height="40"
          label
          v-if="isCorrectlyAnswered(index)"
          color="blue lighten-4"
          >{{ quest.placedanswer
          }}<v-icon color="blue">mdi-checkbox-marked-circle</v-icon>
        </v-chip>
        <v-chip
          min-height="35"
          label
          v-if="!isCorrectlyAnswered(index)"
          color="red lighten-4"
          >{{ quest.placedanswer }}<v-icon color="red">mdi-alert</v-icon>
        </v-chip>
      </v-col>
      <v-col cols="7" sm="9" md="10" class="my-1 py-0 mx-0 px-0 my-sm-2">
        <v-card
          min-height="33"
          v-bind:color="getSelectedColor(index)"
          solo
          dense
          flat
          readonly
          class="mx-0 px-2 pt-1"
          v-bind:value="quest.title"
          >{{ quest.title }}
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div
          v-for="(postguideitem, index) in gvars.postguideitems"
          :key="index"
        >
          <div>
            <v-card>
              <v-card-text v-html="postguideitem.basetext"></v-card-text>
              <v-img
                v-if="Boolean(postguideitem.imgfile)"
                v-bind:src="getImageUrlCommonMethod(postguideitem.imgfile)"
                v-bind:height="
                  getCoefficientHeight(
                    postguideitem.imgwidth,
                    postguideitem.imgheight
                  )
                "
                v-bind:width="getCoefficientWidth(postguideitem.imgwidth)"
              ></v-img>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row><v-col></v-col></v-row>
    <v-row><v-col></v-col></v-row>
    <ask-impression-block></ask-impression-block>
  </v-container>
</template>

<script lang="js">
import AskImpressionBlock from '@/components/AskImpressionBlock.vue';

import cm from '@/mixins/commonMethods';



const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "ResultlistPanel",

  mixins:[cm],

  components:{
    AskImpressionBlock,
  },

  props: {
    openCommentaryEmitter:{
        type: Function,
        required:true,
    },
  },

  data: () => ({
    gvars:{},
  }),

  computed: {
  },

  created: function(){
    debugLog("ResultlistPanel created");
    this.gvars=this.$store.getters['vars/gv'];
    debugLog("vars/gv.testdata:", this.$store.getters['vars/gv'].testdata);

  },

  mounted: function(){
    debugLog("ResultlistPanel mounted");

  },

  methods:{
    // 解説参照リンクの処理
    triggerOpenCommentary: function(index){
      debugLog("triggerOpenCommentary:",index);
      this.openCommentaryEmitter(index);
    },

    isCorrectlyAnswered(index){
      return this.gvars.questions[index].donecorrectly;
    },

    getSelectedColor: function(index){
      if(index === this.gvars.selectedCommentaryIndex) return "blue lighten-4";
      else return "white";
    },
  },
};
</script>

<style></style>
