<template>
  <v-container v-if="this.gvars.activePage === 'TESTING'">
    <v-row>
      <v-col>
        <v-text-field
          name="q_title"
          prefix="問題名:"
          v-model="gvars.currentquestion.title"
          color="cyan lighten-4"
          class="v-text-field-qtitle"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row style="justify-content: flex-end; background-color: white">
      <v-chip label>{{ gvars.qnums.length }}問中 {{ gvars.qnum }}問目</v-chip>
      <v-chip label class="ml-2">残り時間 {{ remainingTime }} </v-chip>
    </v-row>

    <text-image-block
      v-if="this.gvars.currentquestion.text1obj.exists"
      :textimage="this.gvars.currentquestion.text1obj"
      :blocklabel="''"
      :propertyselector="'flat'"
    ></text-image-block>

    <text-image-block
      v-if="this.gvars.currentquestion.text2obj.exists"
      :textimage="this.gvars.currentquestion.text2obj"
      :blocklabel="'【設問】'"
      :propertyselector="'outlined'"
    ></text-image-block>

    <text-image-block
      v-if="this.gvars.currentquestion.text3obj.exists"
      :textimage="this.gvars.currentquestion.text3obj"
      :blocklabel="'【課題文】'"
      :propertyselector="'outlined'"
    ></text-image-block>

    <v-row
      v-if="this.gvars.currentquestion.text4obj.exists && isHintDisabled()"
      justify="center"
    >
      <v-col align="center" cols="6">
        <v-btn
          color="cyan lighten-4"
          v-on:click="enableHint(true)"
          height="60"
          block
          >ヒントを見る</v-btn
        >
      </v-col>
    </v-row>

    <text-image-block
      v-if="this.gvars.currentquestion.text4obj.exists && isHintEnabled()"
      :textimage="this.gvars.currentquestion.text4obj"
      :blocklabel="'【ヒント】'"
      :propertyselector="'outlined'"
    ></text-image-block>

    <!-- TEXTFIELD 型問題の選択肢 start -->
    <div v-if="this.gvars.currentquestion.ansmethod == 'TEXTFIELD'">
      <v-row><v-col></v-col></v-row>
      <v-row
        ><v-col style="background-color: #e0e0e0" class="ma-2 py-1 px-3">
          入力欄
        </v-col></v-row
      >

      <v-container v-if="this.$store.getters['mode/verbose']">
        {{ this.gvars.currentquestion.ansmethod }}
      </v-container>
      <div
        v-for="(optitem, index) in this.gvars.currentquestion.optarray"
        :key="index"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-bind:prefix="optitem.fieldname"
              v-bind:hint="optitem.fieldcaption"
              v-model="optitem.fieldvalue"
              outlined
              prepend-icon="mdi-pencil"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- TEXTFIELDS 型問題の選択肢 end -->

    <!-- CHOICE 型問題の選択肢 start -->
    <v-radio-group
      v-model="gvars.placedanswer"
      v-if="this.gvars.currentquestion.ansmethod == 'CHOICE'"
    >
      <v-container v-if="this.$store.getters['mode/verbose']">
        {{ this.gvars.currentquestion.ansmethod }}
      </v-container>
      <div
        v-for="(optitem, index) in this.gvars.currentquestion.optarray"
        :key="index"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12">
            <v-radio v-bind:value="index + 1" align="center">
              <template v-slot:label>
                <div v-bind:style="selectTcs('standardtext')">
                  {{ optitem.basetext }}
                </div>
              </template>
            </v-radio>
            <div v-if="Boolean(optitem.imgfile)">
              <v-card v-on:click="gvars.placedanswer = index + 1">
                <v-img
                  v-bind:src="getImageUrlCommonMethod(optitem.imgfile)"
                  v-bind:height="
                    getCoefficientHeight(optitem.imgwidth, optitem.imgheight)
                  "
                  v-bind:width="getCoefficientWidth(optitem.imgwidth)"
                ></v-img>
              </v-card>
            </div>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row height="20"><v-col></v-col></v-row>
      </div>
    </v-radio-group>
    <!-- CHOICE 型問題の選択肢 end -->

    <v-row>
      <v-col></v-col>
    </v-row>
    <div v-if="isShowCommBtnActive()">
      <!-- 「解説表示」 -->
      <v-row style="justify-content: space-around; background-color: white">
        <v-col
          align="center"
          cols="7"
          sm="6"
          md="6"
          style="background-color: white"
        >
          <v-btn color="info" v-on:click="showImmediateComm()" block
            >解説表示</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <div v-if="!isShowCommBtnActive()">
      <v-row style="justify-content: space-around; background-color: white">
        <v-col
          align="center"
          cols="7"
          sm="6"
          md="6"
          style="background-color: white"
        >
          <v-btn
            v-bind:disabled="gvars.placeAnswerBtnDisabled"
            color="info"
            v-on:click="placeAnswerAquestion(true)"
            block
            >解答して次へ</v-btn
          >
        </v-col>
        <v-col align="center" cols="4" sm="4" md="3">
          <v-btn
            v-bind:disabled="gvars.passAnswerBtnDisabled"
            color="info"
            v-on:click="placeAnswerAquestion(false)"
            block
            >パスする</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <commentary-block
      v-if="isImmediateCommActive()"
      :commentaryitems="this.gvars.currentquestion.commentaryitems"
    ></commentary-block>

    <v-row v-if="isTrialMessageExists">
      <v-card width="700" class="mx-auto" align="center">
        <h3>{{ this.gvars.trialMessage }}</h3>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="js">

import CommentaryBlock from '@/components/CommentaryBlock.vue';
import TextImageBlock from '@/components/TextImageBlock.vue';

import cm from '../mixins/commonMethods';
const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "TestingPanel",

  mixins:[cm],

  components:{
    CommentaryBlock,
    TextImageBlock,
  },

  props: {
    acceptAnswerEmitter:{
        type: Function,
        required:true,
    },
  },

  data: () => ({
  }),

  created: function(){
    debugLog("TestingPanel created");
    this.gvars=this.$store.getters['vars/gv'];
    debugLog("vars/gv.testdata:", this.$store.getters['vars/gv'].testdata);
  },

  computed: {
  },

  mounted: function(){
    debugLog("TestingPanel mounted");
  },

  methods:{
    placeAnswerAquestion: function(passflag){
      debugLog("clickAnswer");
      this.acceptAnswerEmitter(passflag);
    },

  },
};
</script>

<style>
.v-label {
  color: black;
}
</style>
