<template>
  <v-container v-if="this.gvars.activePage === 'COMMENTARY'">
    <v-row>
      <v-col>
        <v-text-field
          name="qc_title"
          prefix="問題名:"
          v-model="gvars.currentquestion.title"
          color="cyan lighten-4"
          class="v-text-field-low"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <commentary-block
      :commentaryitems="this.gvars.currentquestion.commentaryitems"
      :blocklabel="'解説'"
    ></commentary-block>
    <v-row><v-col></v-col></v-row>
    <v-row>
      <v-col align="center">
        <v-btn color="info" v-on:click="triggerCloseCommentary" width="200px"
          >前ページへ戻る</v-btn
        >
      </v-col>
    </v-row>
    <v-row><v-col></v-col></v-row>
    <v-row>
      <v-col
        cols="12"
        align="center"
        class="ma-2 py-1"
        style="background-color: #00897b; color: white"
      >
        （以下、出題を再掲）
      </v-col>
    </v-row>
    <v-row><v-col></v-col></v-row>
    <v-row v-if="isText1Exists()">
      <v-col>
        <v-card>
          <v-card-text
            v-html="convertNewlineToBR(gvars.currentquestion.text1obj.basetext)"
          ></v-card-text>
        </v-card>
        <div v-if="Boolean(gvars.currentquestion.text1obj.imgfile)">
          <v-card>
            <v-img
              v-bind:src="
                getImageUrlCommonMethod(gvars.currentquestion.text1obj.imgfile)
              "
              v-bind:height="
                getCoefficientHeight(
                  gvars.currentquestion.text1obj.imgwidth,
                  gvars.currentquestion.text1obj.imgheight
                )
              "
              v-bind:width="
                getCoefficientWidth(gvars.currentquestion.text1obj.imgwidth)
              "
            ></v-img>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row
      ><v-col
        style="background-color: #e0e0e0"
        class="mx-2 mt-2 mb-0 py-1 px-3"
      >
        設問
      </v-col></v-row
    >
    <v-row>
      <v-col>
        <v-card>
          <v-card-text
            v-html="convertNewlineToBR(gvars.currentquestion.text2obj.basetext)"
          ></v-card-text>
          <v-img
            v-if="Boolean(gvars.currentquestion.text2obj.imgfile)"
            v-bind:src="
              getImageUrlCommonMethod(gvars.currentquestion.text2obj.imgfile)
            "
            v-bind:height="
              getCoefficientHeight(
                gvars.currentquestion.text2obj.imgwidth,
                gvars.currentquestion.text2obj.imgheight
              )
            "
            v-bind:width="
              getCoefficientWidth(gvars.currentquestion.text2obj.imgwidth)
            "
          ></v-img>
        </v-card>
      </v-col>
    </v-row>

    <v-row><v-col></v-col></v-row>
    <div v-if="isText3Exists()">
      <v-row
        ><v-col
          style="background-color: #e0e0e0"
          class="mx-2 mt-2 mb-0 py-1 px-3"
        >
          課題文
        </v-col></v-row
      >
      <v-row>
        <v-col>
          <v-card>
            <v-card-text
              v-html="
                convertNewlineToBR(gvars.currentquestion.text3obj.basetext)
              "
            ></v-card-text>
            <v-img
              v-if="Boolean(gvars.currentquestion.text3obj.imgfile)"
              v-bind:src="
                getImageUrlCommonMethod(gvars.currentquestion.text3obj.imgfile)
              "
              v-bind:height="
                getCoefficientHeight(
                  gvars.currentquestion.text3obj.imgwidth,
                  gvars.currentquestion.text3obj.imgheight
                )
              "
              v-bind:width="
                getCoefficientWidth(gvars.currentquestion.text3obj.imgwidth)
              "
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- TEXTFIELD 型問題の選択肢 start -->
    <div v-if="this.gvars.currentquestion.ansmethod == 'TEXTFIELD'">
      <v-row><v-col></v-col></v-row>
      <div
        v-for="(optitem, index) in this.gvars.currentquestion.optarray"
        :key="index"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="10">
            <v-text-field
              v-bind:value="optitem.fieldanswer"
              v-bind:prefix="optitem.fieldname"
              readonly
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row height="20"><v-col></v-col></v-row>
      </div>
    </div>
    <!-- TEXTFIELDS 型問題の選択肢 end -->

    <!-- CHOICE 型問題の解答 start -->
    <div v-if="this.gvars.currentquestion.ansmethod == 'CHOICE'">
      <v-row><v-col></v-col></v-row>
      <v-row
        ><v-col style="background-color: #e0e0e0" class="ma-2 py-1 px-3">
          選択肢
        </v-col></v-row
      >
      <div
        v-for="(optitem, index) in this.gvars.currentquestion.optarray"
        :key="index"
      >
        <v-row>
          <v-col cols="2">
            <v-chip
              min-height="35"
              v-if="isRightAnswer(index)"
              color="blue lighten-4"
              >正解
            </v-chip>
          </v-col>
          <v-col cols="10">
            <v-card
              min-height="35"
              v-bind:color="getRightColor(index)"
              class="py-2 pl-2 ma-0"
              >{{ optitem.basetext }}
              <div v-if="Boolean(optitem.imgfile)">
                <v-img
                  v-bind:src="getImageUrlCommonMethod(optitem.imgfile)"
                  v-bind:height="
                    getCoefficientHeight(optitem.imgwidth, optitem.imgheight)
                  "
                  v-bind:width="getCoefficientWidth(optitem.imgwidth)"
                >
                </v-img>
              </div>
            </v-card>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-row height="20"><v-col></v-col></v-row>
      </div>
    </div>
    <!-- CHOICE 型問題の解答 end -->

    <div v-if="isText4Exists()">
      <v-row
        ><v-col
          style="background-color: #e0e0e0"
          class="mx-2 mt-2 mb-0 py-1 px-3"
        >
          ヒント
        </v-col></v-row
      >
      <v-row>
        <v-col>
          <v-card>
            <v-card-text
              v-html="
                convertNewlineToBR(gvars.currentquestion.text4obj.basetext)
              "
            ></v-card-text>
            <v-img
              v-if="Boolean(gvars.currentquestion.text4obj.imgfile)"
              v-bind:src="
                getImageUrlCommonMethod(gvars.currentquestion.text4obj.imgfile)
              "
              v-bind:height="
                getCoefficientHeight(
                  gvars.currentquestion.text4obj.imgwidth,
                  gvars.currentquestion.text4obj.imgheight
                )
              "
              v-bind:width="
                getCoefficientWidth(gvars.currentquestion.text4obj.imgwidth)
              "
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row>
      <v-col align="center">
        <v-btn color="info" v-on:click="triggerCloseCommentary" width="200px"
          >前ページへ戻る</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">

import CommentaryBlock from '@/components/CommentaryBlock.vue';

import cm from '@/mixins/commonMethods';


const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "CommentaryPanel",

  mixins:[cm],

  components:{
    CommentaryBlock,
  },

  props: {
    closeCommentaryEmitter:{
        type: Function,
        required:true,
    },
  },

  data: () => ({
    gvars:{},
  }),

  computed: {
  },

  created: function(){
    debugLog("CommentaryPanel created");
    this.gvars=this.$store.getters['vars/gv'];
    debugLog("vars/gv.testdata:", this.$store.getters['vars/gv'].testdata);
  },

  mounted: function(){
    debugLog("CommentaryPanel mounted");

  },

  methods:{
    // 解説参照リンクの処理
    triggerCloseCommentary: function(){
      debugLog("triggerCloseCommentary");
      this.closeCommentaryEmitter();
    },

    isCorrectlyAnswered(index){
      return this.gvars.questions[index].donecorrectly;
    },

    getSelectedColor: function(index){
      if(index === this.gvars.selectedCommentaryIndex) return "blue lighten-4";
      else return "white";
    },


  },
};
</script>

<style></style>
