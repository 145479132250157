<template>
  <div ref="controllerPageTop">
    <!-- ------------- 開始案内ページ ------------- -->
    <preguide-panel
      v-if="this.gvars.activePage === 'PREGUIDE'"
      :start-testing-aquestion-emitter="startTestingAquestion"
    />
    <!-- ------------- テスティングページ ------------- -->
    <testing-panel
      v-if="this.gvars.activePage === 'TESTING'"
      :accept-answer-emitter="acceptAnswer"
    />
    <!-- ------------- 結果一覧ページ ------------- -->
    <resultlist-panel
      v-if="this.gvars.activePage === 'RESULTLIST'"
      :open-commentary-emitter="openCommentary"
    />
    <!-- ------------- 解説確認ページ ------------- -->
    <commentary-panel
      v-if="this.gvars.activePage === 'COMMENTARY'"
      :close-commentary-emitter="this.closeCommentary"
    />
    <!-- ------------- 完了ページ ------------- -->
    <farewell-panel
      v-if="this.gvars.activePage === 'FAREWELL'"
      :restart-testunit-session-emitter="restartTestunitSession"
      :backto-resultlist-emitter="this.backtoResultlist"
    />
    <!-- ------------- 最下段にsystemMessageを出す ------------- -->
    <system-message :systemMessage="this.gvars.systemMessage" />
  </div>
</template>

// scriptタグ内にスクリプト
<script lang="js">
import Vue from 'vue';
import { DateTime } from "luxon";

import {getRecords} from '../client/client.js';
import {postRecord} from '../client/client.js';


import PreguidePanel from '@/components/PreguidePanel.vue';
import ResultlistPanel from '@/components/ResultlistPanel.vue';
import CommentaryPanel from '@/components/CommentaryPanel.vue';
import FarewellPanel from '@/components/FarewellPanel.vue';
import TestingPanel from '@/components/TestingPanel.vue';
import SystemMessage from '@/components/SystemMessage.vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};

export default Vue.extend({
  name: 'ControllerPage',

  mixins:[cm],

  components:{
    PreguidePanel,
    ResultlistPanel,
    CommentaryPanel,
    FarewellPanel,
    TestingPanel,
    SystemMessage,
  },


  props:{
    code:{
      type:String,
      required: false,
      default:''
    },
    mode:{
      type:String,
      required: false,
      default:'',
    },
  },

  data: () => ({

  }),


  created: function(){
    debugLog('ControllerPage Created');
    this.initializeStaticParameters(this.mode);
    this.gvars=this.$store.getters['vars/gv'];

    //↓ここでページをクリアしておかないと、preguidepanelが先に更新されて、1つ前のデータが表示されてしまう
    this.gvars.activePage = 'NONE';
  },

  mounted: async function(){
    debugLog('ControllerPage mounted');
    debugLog('code:', this.code);
    debugLog('mode:', this.mode);
    document.title = this.$route.meta.title + ' | ' + process.env.VUE_APP_SITENAME;
    debugLog(document.title);

    this.restartTestunitSession(); // /:code で指定されたテストユニットを開始する
  },

  computed: {
  },


  methods: {

    scrollToRef(refName){
      const el = this.$refs[refName];
      el.scrollIntoView({behavior:'smooth'});
    },


    restartTestunitSession: function(){
      this.clearData();
      this.beginTestunitSession(this.code);
    },

    clearData: function(){
      this.clearSystemMessage();
      this.clearTrialMessage();
      this.gvars.questions=[];
      this.gvars.qnums=[];
      this.gvars.qnum=1;
      this.gvars.note.impression='';
      this.gvars.selectedCommentaryIndex='';
      this.clearAnswer();
      this.disableHint();
    },

    clearAnswer: function(){
      this.gvars.placedanswer=0;
    },


    // テストユニットを開始する
    // TU データを取得して preguide へ遷移
    beginTestunitSession: async function(tid){
      debugLog('beginTestunitSession');
      if(tid<=0){
        debugLog('bad tid');
        this.setSystemMessage("パラメータが間違っています");
        return;
      }

      debugLog('calling /gs/CTU/');
      const result = await getRecords('/gs/CTU/', this.code);
      if(result.resultcode != 'OK'){
        debugLog(result);
        this.setSystemMessage("データを取得できません");
        return;
      }
      const record = result.rows[0];

      this.gvars.testunit.status      = record.status;
      this.gvars.testunit.tid         = record.tid;
      this.gvars.testunit.code        = record.code;
      this.gvars.testunit.rev         = record.rev;
      this.gvars.testunit.tag         = record.tag;
      this.gvars.testunit.title       = record.title;
      this.gvars.testunit.preguide    = record.preguide;
      this.gvars.testunit.qset        = record.qset;
      this.gvars.testunit.postguide   = record.postguide;
      this.gvars.testunit.timelimit   = record.timelimit;

      this.setQnums(this.gvars.testunit.qset);

      document.title = '問題：' + record.title + ' | ' + process.env.VUE_APP_SITENAME;
      this.switch2Preguide();
    },

    // qset は先頭行に #ID# または #CODE# を書き、
    // 10275:コメント の書式で行頭にIDまたはCODE、後ろにコメントを書く
    setQnums: async function(qset){
      debugLog("setQnums");
      if(qset.match(/^#ID#/)){
        debugLog("#ID#");
        const qn = qset.match(/^[0-9]+/gm); // 行頭の数字のみ取得
        debugLog(qn);
        this.gvars.qnums = qn.map(x => parseInt(x));
      }
      else{
        debugLog("#CODE#");
        const cn = qset.match(/^[0-9a-zA-Z]+/gm);
        debugLog(cn);
        const qn=[];
        for(const code of cn){
          debugLog(code);
          const result = await getRecords('/gs/CQT/', code);
          if(result.resultcode!='OK'){
            debugLog(result);
            this.setSystemMessage("データを取得できません");
            return;
          }
          debugLog("qid=" + result.rows[0].qid);
          qn.push(result.rows[0].qid);
        }
        debugLog(qn);
        this.gvars.qnums=qn.map(x => parseInt(x));
      }
    },



    startIntervalTimer: function(){
      this.gvars.startedtime = DateTime.now();
      if(this.gvars.testunit.timelimit==0)  {
          this.gvars.finishingtime = DateTime.now().plus({minutes: 60});
      }else{
          this.gvars.finishingtime = DateTime.now().plus({minutes: this.gvars.testunit.timelimit});
          this.gvars.timerId = setInterval(this.countDownSeconds, 1000);
          debugLog(`timerId:${this.gvars.timerId}`);
      }
      this.gvars.remainingseconds = Math.floor(this.gvars.finishingtime.diff(this.gvars.startedtime) /1000);
    },

    clearIntervalTimer: function(){
      if(this.gvars.timerId){
        clearInterval(this.gvars.timerId);
      }
      this.gvars.timerId=0;
    },


    // テスト開始ボタンのハンドラー
    // ひとつのquestionの処理を始める
    startTestingAquestion: function(){
      this.gvars.placeAnswerBtnDisabled=false;
      this.startIntervalTimer();
      this.switch2Testing();
      this.resetQNum();
      this.execAQuestion();
    },


    countDownSeconds: function(){
      const now = DateTime.now();
      const remaining = Math.floor(this.gvars.finishingtime.diff(now) /1000);
      if(remaining<=0){
        this.gvars.isTimeup = true;
        this.setTrialMessage("制限時間になりました");
        this.clearIntervalTimer();
//        this.gvars.placeAnswerBtnDisabled=true;
      }
      this.gvars.remainingseconds = remaining;
    },

    isEmpty: function(v){
      if(v === null) return true;
      if(v === undefined) return true;
      if(v === "") return true;
    },

    // 解答ボタンのハンドラー
    acceptAnswer: function(passflag){
      const now = DateTime.now();
      debugLog('acceptAnswer clicked');
      debugLog(passflag);
      debugLog('answer:' + this.gvars.placedanswer);
      if(this.gvars.currentquestion.ansmethod=='CHOICE'){
        if(!passflag || this.isEmpty(this.gvars.placedanswer) ){this.gvars.placedanswer=0};
        this.gvars.questions[this.gvars.qnum-1].placedanswer=this.gvars.placedanswer;
        this.gvars.questions[this.gvars.qnum-1].donecorrectly = (this.gvars.placedanswer == this.gvars.questions[this.gvars.qnum-1].rightanswer);
      }
      else if(this.gvars.currentquestion.ansmethod=='TEXTFIELD'){
        debugLog('--------optarray--------');
        debugLog(this.gvars.currentquestion.optarray);
        this.gvars.questions[this.gvars.qnum-1].optarray=this.gvars.currentquestion.optarray;
        this.gvars.questions[this.gvars.qnum-1].donecorrectly ='NA';
      }

      this.gvars.questions[this.gvars.qnum-1].lapseconds = Math.floor(now.diff(this.gvars.lapstartedtime) /1000);
      debugLog(this.gvars.questions[this.gvars.qnum-1]);
      // 正誤チェック
      // 最後の問題であればResultlistへ遷移する
      // this.switch2Resultlist();
      if(this.proceedQNum()){
        this.execAQuestion();
      }else{
        this.clearIntervalTimer();  // 最後の問題なのでカウントダウンタイマーを止める
        this.gvars.isTimeup=false;
        this.gvars.usedseconds = Math.floor(now.diff(this.gvars.startedtime) /1000);
        this.gvars.finishedtime = now.toFormat('yyyy-MM-dd TT');
        debugLog(this.gvars.finishedtime);
        setTimeout(this.sendResults,100);
        this.clearSystemMessage();
        this.switch2Resultlist();
      }

    },

    resetQNum: function(){
      this.gvars.qnum=1;
    },

    // 処理中の問題番号を1つ進める。処理できる場合は true, 末尾に達していれば falseを返す
    proceedQNum: function(){
      if(this.gvars.qnum < this.gvars.qnums.length ){
        debugLog('gvars.qnum proceeding')
        this.gvars.qnum++;
        return true;
      }
      else{
        debugLog('gvars.qnum is maximum')
        return false;
      }
    },

    execAQuestion: async function(){
      debugLog('beginAQuestion');
      debugLog(this.gvars.qnums);
      this.hideImmediateComm();
      this.disableHint();
      this.clearAnswer();
      if(this.gvars.qnum > this.gvars.qnums.length)
      {
        debugLog('gvars.qnum exceeded gvars.qnums');
        this.setSystemMessage("該当するデータはありません");
        return;
      }
      const qid = this.gvars.qnums[this.gvars.qnum-1];
      if(qid<=0){
        debugLog('bad qid');
        this.setSystemMessage("パラメータが間違っています");
        return;
      }

      debugLog('calling /gs/1QT/');
      const result = await getRecords('/gs/1QT/', qid);
      if(result.resultcode!='OK'){
        this.setSystemMessage("データを取得できません");
        return;
      }
      debugLog(result);
      const record = result.rows[0];

      debugLog('------this.gvars.currentquestion-------');
      debugLog(this.gvars.currentquestion);

      //const testobj = await this.splitTextAndImage("");
      //debugLog("■■■testobj■:",testobj);

      record.text1obj = await this.splitTextAndImage(record.text1);
      debugLog("text1:" , record.text1);
      debugLog("text1obj:" , record.text1obj);
      record.text2obj = await this.splitTextAndImage(record.text2);
      debugLog("text2:" , record.text2);
      debugLog("text2obj:" , record.text2obj);
      record.text3obj = await this.splitTextAndImage(record.text3);
      debugLog("text3:" , record.text3);
      debugLog("text3obj:" , record.text3obj);
      record.text4obj = await this.splitTextAndImage(record.text4);
      debugLog("text4:" , record.text4);
      debugLog("text4obj:" , record.text4obj);

      debugLog("options:" , record.options);
      debugLog("ansmethod:" , record.ansmethod);
      if(record.ansmethod=='CHOICE'){
        record.optarray = await this.getOptArray(record.options);
      }
      else if(record.ansmethod=='TEXTFIELD') {
        record.optarray = await this.getFieldArray(record.options);
      }
      else{
        console.assert(true, 'gvars.currentquestion.ansmethod value ERROR' + this.gvars.currentquestion.ansmethod);
      }
      debugLog("commentary:",record.commentary);
      record.commentaryitems = this.splitCommentary(record.commentary);
      this.gvars.questions.push ({...record});
      this.gvars.currentquestion = record;

      debugLog('------this.gvars.currentquestion-------');
      debugLog(this.gvars.currentquestion);
      debugLog("isShowCommBtnActive:", this.isShowCommBtnActive());


      window.scrollTo(0,0);
      // ここで this.gvars.lapstartedtime を記録する
      this.gvars.lapstartedtime = DateTime.now();
    },


    // 画像サイズの読み出し関係の処理が splitTextAndImage と違うがそのうち整理したい
    splitCommentary: function(str){
      debugLog("Commentary:" + str);
      // 解説 (commentary) を複数セクションにするときは ---------で区切ること
      // 1つのセクションの中には PICT と HREF のどちらか片方しか入れられない
      const sections=str.split(/^---------*$/gm); // 区切り行で分離した部分文字列の配列
      debugLog(sections);
      const comarray=sections.map(function(section){
        debugLog("splitCommentary section:" , section);

        // 最初はsection全体を basetext に入れる
        let comtobj = {basetext:'', imgfile:'', imgcaption:'', hrefurl:'', hrefcaption:''};

        // 画像が入っているかどうかチェック
        let array2=section.split(/<<PICT:(.*\..*):(.*)>>/m); // 部分文字列を PICT 要素で分離した配列
        debugLog("splitCommentary array2 PICT")
        debugLog(array2)
        if(array2.length>=2){
//          const basetext = array2[0].replace(/\n/g,'<br />');
          const imgfile = array2[1];
          const imgcaption = array2[2];
          //↓画像が入っているときは basetext も含めて更新する
//          comtobj.basetext = basetext;
          comtobj.imgfile = imgfile;
          comtobj.imgcaption = imgcaption;

          // map の処理の中で await を使わないようにしているので、ここではloadImageSizeを使わない。
          // 呼び出し元に返ってから for で loadImageSize を回している
        }

        // HREFが入っているかどうかチェック
        array2=section.split(/<<HREF:(.*\..*):(.*)>>/m); // 部分文字列を HREF 要素で分離した配列
        debugLog("splitCommentary array2 HREF")
        debugLog(array2)
        if(array2.length>=2){
//          const basetext = array2[0].replace(/\n/g,'<br />');
          const hrefurl = array2[1];
          const hrefcaption = array2[2];

          //↓HREFが入っているときは basetext も含めて更新する
//          comtobj.basetext = basetext;
          comtobj.hrefurl = hrefurl;
          comtobj.hrefcaption = hrefcaption;
        }

        section = section.replace(/<<PICT:(.*\..*):(.*)>>/m, '');
        comtobj.basetext = section.replace(/<<HREF:(.*\..*):(.*)>>/m, '');

        return comtobj;
      });
      debugLog("splitCommentary:" , comarray);
      return comarray;
    },


    hasImage: function(index){
      return Boolean(this.gvars.currentquestion.options[index].imgfile);
    },

    // question オブジェクトを受け取って qresult の形に構成したオブジェクトを返す
    arrangeResultObject: function(x){
      if(x.ansmethod=='CHOICE'){
        debugLog('------arrangeResultObject------CHOICE');
        return{qid:x.qid, code:x.code, ansmethod:x.ansmethod, placedanswer:x.placedanswer,donecorrectly:x.donecorrectly,lapseconds:x.lapseconds};
      }
      else if(x.ansmethod=='TEXTFIELD'){
        debugLog('------arrangeResultObject------TEXTFIELD');
        debugLog(x);
        const textentries = {};
        x.optarray.forEach(element => {
          textentries[element.fieldname] = element.fieldvalue;
          });

        return{qid:x.qid, code:x.code, ansmethod:x.ansmethod, textentries:textentries, donecorrectly:'NA', lapseconds:x.lapseconds}
      }
      else{
        console.assert(true, 'gvars.currentquestion.ansmethod value ERROR' + this.gvars.currentquestion.ansmethod);
        return {};
      }
    },

    // 解答データを送信する
    sendResults: async function(){
      const qresult=this.gvars.questions.map(this.arrangeResultObject );
      const resultParams={
        usedseconds:this.gvars.usedseconds,
        finishedtime:this.gvars.finishedtime,
        tid:this.gvars.testunit.tid,
        code:this.gvars.testunit.code,
        qresult:qresult
      };

      debugLog(resultParams);

      debugLog(`(${this.mode}) calling /gs/WQR/`);
      if(!this.$store.getters['mode/verifyonly']){
        const result = await postRecord('/gs/WQR/', resultParams);
        debugLog(result);
      }
    },


    // 解説ページを開く
    openCommentary: async function(index) {
      this.gvars.selectedCommentaryIndex = index;
      this.gvars.currentquestion = this.gvars.questions[index];
      debugLog("currentquestion:", this.gvars.currentquestion);

      // ここに画像サイズ取得の処理を入れる
      for(var comitem of this.gvars.currentquestion.commentaryitems){
          debugLog(`openCommentary:comitem: ${comitem}`);
          if(comitem.imgfile){
            const res = await this.loadImageSizeCommon(this.getImageUrlCommonMethod(comitem.imgfile));
            comitem.imgwidth=res.width;
            comitem.imgheight=res.height;
          }
      }

      debugLog(this.gvars.currentquestion.commentaryitems);
      this.switch2Commentary();
    },



    // 解説ページのクローズは単に Resultlist に戻るだけ
    closeCommentary: function() {
      this.switch2Resultlist();
    },

    // 完了ページのクローズは単に Resultlist に戻るだけ
    backtoResultlist: function() {
      this.switch2Resultlist();
    },



    switch2Preguide: async function() {
      // PreguidePanel用のパラメータを作ってページを切替え

      const preguideparams={
        title   :this.gvars.testunit.title,
        preguide:this.gvars.testunit.preguide,
//        preguideitems : [this.splitCommentary(this.gvars.testunit.preguide)],

        preguideitems : [await this.splitTextAndImage(this.gvars.testunit.preguide)],
      };
      debugLog("preguideparams:", preguideparams);
      this.$store.dispatch('vars/setPreguideparams', preguideparams);

      this.gvars.activePage = 'PREGUIDE';
    },

    /* methods for page transition */
    switch2Testing: function() {
      this.gvars.activePage = 'TESTING';
    },

    switch2Resultlist: async function() {
      this.gvars.postguideitems = [await this.splitTextAndImage(this.gvars.testunit.postguide)];
      this.gvars.activePage = 'RESULTLIST';
      setTimeout(function(){window.scrollTo(0,0)}, 300);
    },

    switch2Commentary: function() {
      this.gvars.activePage = 'COMMENTARY';
      setTimeout(function(){window.scrollTo(0,0)}, 300);
    },

    switch2Farewell: function() {
      this.gvars.activePage = 'FAREWELL';
    },

  },
});
</script>
