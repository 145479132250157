<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        align="center"
        class="ma-2 py-1"
        style="background-color: #00897b; color: white"
      >
        よろしければ感想/要望/質問等をお送りください
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" align="right">
        <v-icon x-large> mdi-lead-pencil </v-icon>
      </v-col>
      <v-col cols="10">
        <v-textarea
          name="impression"
          placeholder="感想：○○の問題が難しかった、疑問：○○という考え方もできるのでは？ など"
          color="cyan lighten-4"
          auto-grow
          v-model="gvars.note.impression"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card min-height="25" flat color="blue lighten-5">
          氏名・メール
          （ニックネーム可、省略可。質問への回答ご希望の場合ご入力ください。）</v-card
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" align="right">
        <v-icon large> mdi-account </v-icon>
      </v-col>
      <v-col cols="3">
        <v-text-field
          name="person_name"
          color="cyan lighten-4"
          class="v-text-field-low"
          placeholder="名前"
          v-model="gvars.note.personName"
        ></v-text-field>
      </v-col>
      <v-col cols="1" align="right">
        <v-icon large> mdi-email </v-icon>
      </v-col>
      <v-col cols="6">
        <v-text-field
          name="person_email"
          color="cyan lighten-4"
          class="v-text-field-low"
          placeholder="aaa@bbb.jp"
          v-model="gvars.note.personEmail"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
    <v-row style="justify-content: center; background-color: white">
      <v-col cols="1">
        <v-progress-circular indeterminate color="primary" v-if="gvars.sending">
        </v-progress-circular>
      </v-col>
      <v-col
        align="center"
        cols="5"
        sm="5"
        md="5"
        style="background-color: white"
      >
        <v-btn
          color="info"
          v-on:click="placeImpression(true)"
          class="px-10"
          v-bind:disabled="gvars.sending"
          >送信する</v-btn
        >
      </v-col>
      <v-col align="center" cols="5" sm="4" md="3">
        <v-btn color="info" v-on:click="placeImpression(false)"
          >このまま終了する</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script lang="js">
import cm from '../mixins/commonMethods';
const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "AskImpressionBlock",

  mixins:[cm],

  props: {
  },

  data: () => ({
  }),

  created: function(){
    debugLog("AskImpressionBlock created");
    this.gvars=this.$store.getters['vars/gv'];
    debugLog("vars/gv.testdata:", this.$store.getters['vars/gv'].testdata);
  },

  computed: {
  },

  mounted: function(){
    debugLog("AskImpressionBlock mounted");
  },

  methods:{
    // [送信する][このまま終了する]ボタンの処理
    placeImpression: async function(sendflag){
      debugLog("placeImpression:",sendflag);
      if(sendflag){
        const ret = await this.acceptImpression();
        if(ret==='OK'){
          this.setSystemMessage('送信完了しました。トップページに戻ります');
          setTimeout(this.clearMessageMoveTop,2000);
        }
        else{
          this.setSystemMessage('送信できませんでした');
        }
      }
      else{
        this.clearMessageMoveTop();
      }
    },

    clearMessageMoveTop:function(){
      this.clearSystemMessage();
      this.toTopPage();
    },

  },
};
</script>

<style></style>
