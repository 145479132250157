<template>
  <v-app>
    <!-- アプリケーションバー -->
    <v-app-bar
      v-bind:color="this.$store.getters['vars/colors'].basecolor"
      app
      dark
      class="px-2 py-0"
    >
      <v-btn icon v-on:click="toTopPage">
        <v-icon class="px-0">mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title class="px-0">{{ titlestring }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!--
      <v-btn icon v-on:click="toMailformPage()">
        <v-icon>mdi-email-edit</v-icon>
      </v-btn>
      -->

      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="toTopPage">
            <v-list-item-title>トップページへ</v-list-item-title>
          </v-list-item>
          <v-list-item @click="toMailformPage">
            <v-list-item-title>ご連絡フォーム</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <!-- 主画面 -->
      <router-view></router-view>
    </v-main>

    <!-- 情報表示 -->
    <v-container v-if="this.$store.getters['mode/verbose']">
      <v-row
        ><v-col v-html="this.$store.getters['info/serverinfo'].html"></v-col>
      </v-row>
      <v-row>
        <v-col
          >bp:{{ this.$vuetify.breakpoint.name }} width:{{
            this.$vuetify.breakpoint.width
          }}</v-col
        ></v-row
      >
    </v-container>
    <v-row><v-col></v-col></v-row>

    <v-footer
      v-bind:color="this.$store.getters['vars/colors'].basecolor"
      app
      dark
      class="text-caption px-4"
    >
      {{ footerstring }}
    </v-footer>
  </v-app>
</template>

<script>
import cm from '@/mixins/commonMethods';

const debugLog =
  process.env.VUE_APP_LOG == 'ON' ? console.log.bind(console) : () => {};

export default {
  name: 'App',

  mixins: [cm],
  components: {},

  props: {
    mode: {
      type: String,
      required: false,
      default: '',
    },
  },

  data: () => ({
    titlestring: process.env.VUE_APP_TITLESTRING,
    footerstring: process.env.VUE_APP_FOOTERSTRING,
  }),

  created: function () {
    debugLog('App created');
    this.initializeGvars('App');
  },

  mounted: function () {
    debugLog('App mounted');
    debugLog('titlestring:', this.titlestring);
    debugLog('Project Name:', process.env.VUE_APP_PROJECTNAME);
    debugLog('Server URL:', process.env.VUE_APP_APIBASE);
    debugLog('Logging enabled');
  },

  methods: {},
};
</script>

<style>
/* ファイルパスに従って@importの後にファイルパスを書き込む */
@import './css/gstcommon.scss';
</style>
