//GS client program

// eslint-disable-next-line no-unused-vars
const gsClientVersion = '0.92 (2021-05-23)';

global.fetch = require('node-fetch');

const serverapibase = process.env.VUE_APP_APIBASE;
const serverapiport = process.env.VUE_APP_APIPORT;
const debugLog =
  process.env.VUE_APP_LOG == 'ON' ? console.log.bind(console) : () => {};

// 検索関数の戻り値のフォーマット
/*
{ 
  resultcode: 'Error', // OK, Error 
  querystatus:'',     // '', 'NOT FOUND','FOUND', 'QUERY FAILURE'
  rowscount:0,        // 検索された row 数
  rows: [],           // 検索された row
  err: '',            // エラー発生時のエラー内容
 };
*/

export async function getRecords(selector, identifier) {
  const url =
    serverapibase +
    (serverapiport ? ':' + serverapiport : '') +
    selector +
    (identifier ? identifier : '');
  debugLog('url=' + url);
  let res = {};
  try {
    res = await fetch(url);
  } catch (err) {
    return {
      resultcode: 'Error',
      querystatus: 'Fetch failure',
      err: err,
    };
  }

  let data = {};
  try {
    data = await res.json();
  } catch (err) {
    return {
      resultcode: 'Error',
      querystatus: 'Json parsing failure',
      err: err,
    };
  }

  return data;
}

export async function postRecord(selector, record) {
  const url =
    serverapibase + (serverapiport ? ':' + serverapiport : '') + selector;
  debugLog('url=' + url);

  const method = 'POST';
  //  debugLog('■postRecord-');
  //  debugLog(record);
  /*
  const params = createURLSearchParams(record);
  debugLog('■params-');
  for (const [key, value] of params.entries()) {
    debugLog('key,value', key, ',', value);
  }
*/

  const body = JSON.stringify(record);
  //  debugLog('■body-');
  //  debugLog(body);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  let res = {};
  try {
    res = await fetch(url, {
      method: method,
      headers: headers,
      body: body,
    });
  } catch (err) {
    return {
      resultcode: 'Error',
      querystatus: 'Fetch failure',
      err: err,
    };
  }

  let data = {};
  try {
    data = await res.json();
  } catch (err) {
    return {
      resultcode: 'Error',
      querystatus: 'Json parsing failure',
      err: err,
    };
  }

  return data;
}

const dummydata = {
  status: 'ACTIVE',
  code: 'code',
  rev: 'rev',
  tag: 'tag',
  type: 'type',
  title: 'title',
  preguide: 'preguide',
  qset: 'qset',
  postguide: 'postguide',
  timelimit: 2,

  text1: 'text1',
  text2: 'text2',
  text3: 'text3',
  text4: 'text4',
  options: 'options',
  rightanswer: 3,
  commentary: 'commentary',

  tid: 5,
  qid: 31,
  qresults: '{jason data}',
  finishedtime: '2020-01-01 18:18:18',
  usedseconds: 3,
  ipaddress: '222.222.222.222',

  personName: 'personName',
  personEmail: 'personEmail',
  impression: 'impression',
};

const sw = 'NONE';
switch (sw) {
  case '1QT':
    getRecords('/gs/1QT/', 1)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'CQT':
    getRecords('/gs/CQT/', 'AC10')
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'LQT':
    getRecords('/gs/LQT/')
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case '1TU':
    getRecords('/gs/1TU/', 1)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'LTU':
    getRecords('/gs/LTU/')
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'CTU':
    getRecords('/gs/CTU/', 'TS11')
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'ITU':
    postRecord('/gs/ITU/', dummydata)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'UTU':
    postRecord('/gs/UTU/', dummydata)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'IQT':
    postRecord('/gs/IQT/', dummydata)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;
  case 'UQT':
    postRecord('/gs/UQT/', dummydata)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'IQR':
    postRecord('/gs/IQR/', dummydata)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;

  case 'IIP':
    postRecord('/gs/IIP/', dummydata)
      .then((data) => {
        debugLog('-----------------------');
        debugLog(data);
      })
      .catch((err) => {
        debugLog(err);
      });
    break;
}
