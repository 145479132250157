import Vue from 'vue';
import Vuex from 'vuex';
import info from './modules/info';
import mode from './modules/mode';
import vars from './modules/vars';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    info,
    mode,
    vars,
  },
});
