const state = {
  colors: '',
  preguideparams: {},
  teststring: '',

  gv: '', // 事実上のグローバル変数群を格納する
};

const getters = {
  colors: (state) => state.colors,
  preguideparams: (state) => state.preguideparams,
  teststring: (state) => state.teststring,
  gv: (state) => state.gv,
};

const actions = {
  setColors({ commit }, colors) {
    commit('setColors', colors);
  },
  setPreguideparams({ commit }, preguideparams) {
    commit('setPreguideparams', preguideparams);
  },
  setTeststring({ commit }, teststring) {
    commit('setTeststring', teststring);
  },
  setGv({ commit }, gv) {
    commit('setGv', gv);
  },
};

const mutations = {
  setColors(state, colors) {
    state.colors = colors;
  },
  setPreguideparams(state, preguideparams) {
    state.preguideparams = preguideparams;
  },
  setTeststring(state, teststring) {
    state.teststring = teststring;
  },
  setGv(state, gv) {
    state.gv = gv;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
