<template>
  <v-container class="ma-0 px-0 py-4">
    <v-row v-if="blocklabel"
      ><v-col
        style="background-color: #e0e0e0"
        class="mx-2 mt-2 mb-0 py-1 px-3"
      >
        {{ blocklabel }}
      </v-col></v-row
    >
    <v-row>
      <v-col>
        <div v-for="(comitem, index) in commentaryitems" :key="index">
          <div>
            <v-card class="pb-2">
              <v-card-text
                v-bind:style="selectTcs('standardtext')"
                v-html="convertNewlineToBR(comitem.basetext)"
              ></v-card-text>
              <v-img
                v-if="Boolean(comitem.imgfile)"
                v-bind:src="getImageUrlCommonMethod(comitem.imgfile)"
                v-bind:height="
                  getCoefficientHeight(comitem.imgwidth, comitem.imgheight)
                "
                v-bind:width="getCoefficientWidth(comitem.imgwidth)"
              ></v-img>
              <div>
                <v-btn
                  class="ml-6"
                  style="color: #0d47a1; font-weight: bold"
                  v-if="Boolean(comitem.hrefurl)"
                  v-bind:href="comitem.hrefurl"
                  target="_blank"
                  ><v-icon color="#0d47a1">mdi-chevron-triple-right</v-icon
                  >{{ comitem.hrefcaption }}</v-btn
                >
              </div>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import Vue from 'vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};

export default Vue.extend({
  name: 'CommentaryBlock',
  props:['blocklabel','commentaryitems'],

  mixins:[cm],

  mounted: function(){
    debugLog("CommentaryBlock/commentaryitems:", this.commentaryitems);
  },
})
</script>
