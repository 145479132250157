<template>
  <div class="toppage">
    <v-container class="pa-0">
      <notification-block-a v-bind:notific="this.notific1" v-if="reloadflag">
      </notification-block-a>
      <v-card
        tile
        outlined
        shaped
        class="ma-0 pa-0"
        style="background-color: white"
        height="40px"
        ><v-card-title class="px-2 py-1">ロジック図解力問題一覧</v-card-title>
      </v-card>

      <v-row
        class="mt-0 mb-1 pl-12"
        v-for="(testunit, index) in this.gvars.testunits"
        v-bind:key="index"
        style="background-color: white; height: 40px"
      >
        <v-col class="ma-0 pl-0" cols="10">
          <v-chip label
            ><router-link
              v-bind:to="getGstLinkString(testunit.code)"
              style="text-decoration: none; color: black"
              >{{ testunit.tid }} : {{ testunit.title }}</router-link
            ></v-chip
          >
        </v-col>
      </v-row>

      <v-card
        tile
        outlined
        shaped
        class="mx-0 mt-8 mb-3 pa-0"
        style="background-color: white"
        height="40px"
        ><v-card-title class="px-2 py-1">作者とのコンタクト</v-card-title>
      </v-card>
      <v-row>
        <v-col>
          <v-btn v-on:click="toMailformPage" block
            >問合・感想・意見を送る</v-btn
          >
        </v-col>
        <v-col>
          <v-btn v-on:click="toIdeacraftOfficial" block
            >アイデアクラフト公式サイト</v-btn
          >
        </v-col>
      </v-row>
      <v-row style="background-color: gray; height: 1px" class="my-10"
        ><v-col></v-col
      ></v-row>
      <!---         権利表記等           ---------->
      <v-row>
        <v-card>
          <v-card-text class="text-caption"
            ><P
              >当サイトは<A href="https://ideacraft.jp" target="_blank"
                >アイデアクラフト</A
              >が運営しています。</P
            >
            <p>
              当サイト上のすべてのコンテンツの著作権は、開米瑞浩が保持しています。（別途著作権表示のあるものを除く）。
            </p>
            <p>
              コンテンツ改善を目的とする統計処理のため、個人情報を含まない解答データを収集しています。
            </p>
            <p>
              感想を送信する最終ページに個人情報（氏名・Email）の欄がありますが入力は任意です。
            </p>
            <p>
              御入力いただいた個人情報は当コンテンツに関する質問への回答にのみ利用し、他の用途には使用しません。
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <!-- ------------- 最下段にsystemMessageを出す ------------- -->
    <system-message :systemMessage="this.gvars.systemMessage" />
  </div>
</template>

// scriptタグ内にスクリプト
<script lang="js">
import Vue from 'vue';

import {getRecords} from '../client/client.js';

import SystemMessage from '@/components/SystemMessage.vue';
import NotificationBlockA from '@/components/NotificationBlockA.vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};

export default Vue.extend({
  name: 'Toppage',

  mixins:[cm],

  components:{
    SystemMessage,
    NotificationBlockA,
  },

  props:{
    mode:{
      type:String,
      required: false,
      default:'',
    }
  },

  data: () => ({
    reloadflag:false,
    notific1:{},
  }),

  created: function(){
    debugLog('Toppage Created');
    this.initializeStaticParameters(this.mode);
    this.gvars=this.$store.getters['vars/gv'];
  },

  mounted: async function(){
    debugLog('Toppage Mounted');
    document.title = this.$route.meta.title + ' | ' + process.env.VUE_APP_SITENAME;
    debugLog(document.title);

    // get TestUnits List so that can display
    await this.getNotific1();
    await this.getTestunits();
        this.reloadflag=true;
//    this.doForceUpdate();
  },

  methods: {
    // forceUpdateをかける
    doForceUpdate:function(){
      this.reloadflag=false;
      this.$forceUpdate();
      this.$nextTick(function(){
        this.reloadflag=true;
      });
    },

    getNotific1: async function(){
        const result = await getRecords('/gs/1SCN/1');
        if(result.resultcode != 'OK'){
            debugLog(result);
            debugLog("/gs/1SCN/1 failure")
            this.setSystemMessage("データを取得できません");
            return;
        }

        this.notific1 = result.rows[0];
        this.notific1.frames = JSON.parse(this.notific1.frames);
        debugLog("notific:", this.notific1);

        // piece パラーメー他の読みとり
        for(let i=0; i<this.notific1.frames[0].pieces.length; i++ ){
          let p = this.notific1.frames[0].pieces[i];
          // p.seq = i;  // 連番は notifics のほうでは使っていない (scenarioでのみ使用)
          p.visible=true;
//          debugLog("analysing pieces 0:",p);
          this.analyseLinkPieceParameters(p);
//          debugLog("analysing pieces 1:",p);
          this.analyseButtonPieceParameters(p);
//          debugLog("analysing pieces 2:",p);
          await this.analyseTextPieceParameters(p);
//          debugLog("analysing pieces 3:",p);
          await this.analyseImagePieceParameters(p);
//          debugLog("analysing pieces 4:",p);
          await this.analyseQuestionPieceParameters(p);
//          debugLog("analysing pieces 5:",p);
        }

        debugLog("notific pieces:", this.notific1.frames[0].pieces);
    },

    getTestunits: async function(){
        const result = await getRecords('/gs/LTUA/');
        if(result.resultcode != 'OK'){
            debugLog(result);
            this.setSystemMessage("データを取得できません");
            return;
        }

        this.gvars.testunits = result.rows;
        debugLog(this.gvars.testunits);
    },
  },
});
</script>
