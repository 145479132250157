<template>
  <v-container v-if="systemMessage">
    <v-row><v-col></v-col></v-row>
    <v-row v-if="systemMessage">
      <v-card width="700" class="mx-auto" align="center" height="50">
        <h3>{{ systemMessage }}</h3>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="js">
import Vue from 'vue';

export default Vue.extend({
  name: 'SystemMessage',
  props:{
    systemMessage:{
      type:String,
      required: false,
      default:''
    },
  },
})
</script>
