import { getRecords } from '../client/client.js';
import { postRecord } from '../client/client.js';

var SHA256 = require('crypto-js/sha256');

const debugLog =
  process.env.VUE_APP_LOG == 'ON' ? console.log.bind(console) : () => {};

let gvars = {
  btndisabled: false,
  testdata: 'gvars.testdata, set in ControllerPage',
  systemMessage: '', // システムメッセージ
  trialMessage: '', // 制限時間メッセージ
  activePage: 'NONE', // 初期状態ではどこのページでもない（初期データをロードしてからPREGUIDEへ変える
  hintEnabled: false,
  immedComm: false, // immediate で「解答表示」を押したときに有効になる

  indeterminate: {},

  testunits: '',

  // testunits のデータ
  testunit: {
    status: '',
    tid: '',
    code: '',
    rev: '',
    tag: '',
    title: '',
    preguide: '',
    qset: '',
    postguide: '',
    timelimit: '',
  },

  // DBから取得したrecordオブジェクトがそのままここに代入されるので、
  // 実際にはこの currentquestion 内のメンバー定義はなくても問題なし
  currentquestion: {
    // status: '',
    // qid: '',
    // code: '',
    // rev: '',
    // tag: '',
    // ansmethod:'',
    // step:'',
    // title: '',
    // text1: '',
    // text2: '',
    // text3: '',
    // text4: '',
    // options: '',
    // rightanswer: '',
    // commentary: '',
    // optarray: [], // options を解析して配列化したもの
    // placedanswer: '', //実際に選択された解答
    // donecorrectly: '', // true=正答, false=誤答
  },

  note: {
    impression: '',
    personName: '',
    personEmail: '',
  },

  // gvars.questions のデータ
  questions: [], // questionの配列
  qnum: 1, // gvars.questions 配列の何番目を実行中かを示す。1オリジン。
  qnums: [], // 開発用のダミーデータ

  placedanswer: 0, // 画面内での選択。1オリジン。0は選択していないかパスを表す
  timerId: '', // インターバルタイマーID
  lapstartedtime: '', // 個別問題の開始時刻（解答時に差分を計算して個別問題の消費時間を記録する）
  startedtime: '', //開始時刻
  finishingtime: '', // 終了予定時刻
  finishedtime: '', //実際に終了した時刻
  usedseconds: '', //消費秒数
  remainingseconds: 0, // 残り時間（秒数）
  isTimeup: false, // 制限時間に達したら true
  selectedCommentaryIndex: '', // 解説表示をクリックされた問題番号。０オリジン。
  placeAnswerBtnDisabled: false,
  passAnswerBtnDisabled: false,

  sending: false, // メール送信中
};

const buttons = ['MORE', 'ANSWER', 'PROCEED', 'FINISH']; // すべてのボタン
//const inqbtns=['MORE','ANSWER'];  // 質問に答えるボタン
const inquiries = ['CHOICE', 'TEXTFIELD']; // すべての質問
const recordingpieces = ['MORE', 'PROCEED', 'CHOICE', 'TEXTFIELD']; // result として recording 対象になる piece の ptype

const tcsnamedefault = 'standardtext';
const textcolorstyles = {
  standardtext: {
    color: 'rgba(0, 0, 0, 1)',
  },
  gray: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  red: {
    color: 'rgba(255, 0, 0, 1)',
  },
  choicebody1: {
    'background-color': 'rgba(230, 230, 230, 1)',
    color: 'rgba(0, 0, 0, 1)',
  },
  choicebody2: {
    'background-color': 'rgba(255, 255, 255, 1)',
    color: 'rgba(0, 0, 0, 1)',
  },
};

export default {
  //
  created() {
    // 新しいコンポーネントが create されて mixin されるたびに呼ばれる
    debugLog('commonMethod mixins created.'); // startup log
  },

  data() {
    return {
      // グローバルにアクセス可能な変数
      // globalVariable: 'this is a global variable',
    };
  },

  computed: {
    remainingTime: function () {
      if (this.gvars.testunit.timelimit == 0) return '無制限';
      const mins = Math.floor(this.gvars.remainingseconds / 60);
      const secs = this.gvars.remainingseconds % 60;
      return String(mins) + ':' + String(secs);
    },
    breakpoint: function () {
      console.log('BP:', this.$vuetify.breakpoint);
      return this.$vuetify.breakpoint;
    },
  },

  methods: {
    // テキストカラースタイルを名前で選択する
    selectTcs: function (tcsname) {
      tcsname = tcsname ? tcsname : tcsnamedefault;
      return textcolorstyles[tcsname];
    },

    setIndeterminate(seq, v) {
      debugLog('SPLITTER setIndeterminate', seq);
      this.gvars.indeterminate[seq] = v;
    },
    isIndeterminate(seq) {
      debugLog('SPLITTER isIndeterminate', seq);
      return this.gvars.indeterminate[seq];
    },

    globalMethod() {
      if (process.env.VUE_APP_LOG) debugLog('this is a global method');
    },

    getGstLinkString: function (code) {
      return '/gst/' + code + '/' + this.$store.getters['mode/modestring'];
    },

    getGscLinkString: function (code) {
      return '/gsc/' + code + '/' + this.$store.getters['mode/modestring'];
    },

    addModeStringToPath: function (path) {
      debugLog('path:', path);
      if (!path.endsWith('/')) path += '/';
      path += this.$store.getters['mode/modestring'];
      debugLog('path:', path);
      return path;
    },

    isDisabled() {
      return this.gvars.btndisabled;
    },

    disableBtn() {
      this.gvars.btndisabled = true;
    },

    enableBtn() {
      this.gvars.btndisabled = false;
    },

    escapeHtml: function (str) {
      str = str.replace(/&/g, '&amp;');
      str = str.replace(/>/g, '&gt;');
      str = str.replace(/</g, '&lt;');
      str = str.replace(/"/g, '&quot;');
      str = str.replace(/'/g, '&#x27;');
      str = str.replace(/`/g, '&#x60;');
      return str;
    },

    // gv は グローバル変数群
    initializeGvars: function (calledin) {
      debugLog('■initializeGvars:', calledin);
      debugLog('Gv:', gvars);
      if (this.$store.getters['vars/gv']) {
        debugLog('vars/gv is already initialized : ', calledin);
      } else {
        debugLog('initializing vars/gv : ', calledin);
        this.$store.dispatch('vars/setGv', gvars);
      }
    },

    // 起動時に指定されて実行中に変化しない静的パラメータを解析し、mode, info にセットする
    initializeStaticParameters: function (mode) {
      debugLog('initializeStaticParameters in commonMethods.js');
      debugLog('mode:', mode);
      const modeDigest = SHA256(mode);
      this.$store.dispatch('mode/setModestring', mode);
      //debugLog(`modeDigest=${modeDigest}`);

      const vbmode = modeDigest == process.env.VUE_APP_VBKEY;
      this.$store.dispatch('mode/setVerbose', vbmode);
      debugLog('vbmode:', vbmode);

      const vomode = modeDigest == process.env.VUE_APP_VOKEY;
      this.$store.dispatch('mode/setVerifyonly', vomode);
      if (vomode) {
        this.$store.dispatch('mode/setVerbose', true);
      }
      debugLog('vomode:', vomode);

      if (vbmode || vomode) {
        debugLog('calling retrieveServerInfo()');
        this.retrieveServeInfo();
      }

      this.$store.dispatch('vars/setColors', {
        basecolor: process.env.VUE_APP_TOPCOLOR,
      });
      debugLog('setColors:basecolor:', process.env.VUE_APP_TOPCOLOR);
    },

    retrieveServeInfo: async function () {
      const result = await getRecords('/gs/INF/');
      if (result.resultcode != 'OK') {
        debugLog(result);
        this.setSystemMessage('データを取得できません');
        return;
      }
      this.serverinfo = result.rows[0];
      debugLog(this.serverinfo);
      this.serverinfo.array = [
        `host:${this.serverinfo.host1}`,
        `db:${this.serverinfo.database}`,
        `questions:${this.serverinfo.questionscnt}`,
        `testunits:${this.serverinfo.testunitscnt}`,
        `qresults:${this.serverinfo.qresultscnt}`,
        `rsimple:${this.serverinfo.rsimplecnt}`,
      ];
      this.serverinfo.html = this.serverinfo.array.join('<br/>');
      this.$store.dispatch('info/setServerinfo', this.serverinfo);
      debugLog('retrieved serverinfo');
    },

    isShowCommBtnActive() {
      debugLog('step:', this.gvars.currentquestion.step);
      debugLog('immedComm:', this.gvars.immedComm);
      return (
        this.gvars.currentquestion.step == 'IMMEDIATE' &&
        this.gvars.immedComm == false
      );
    },

    showImmediateComm() {
      this.gvars.immedComm = true;
    },

    hideImmediateComm() {
      this.gvars.immedComm = false;
    },

    isImmediateCommActive() {
      debugLog('step:', this.gvars.currentquestion.step);
      debugLog('immedComm:', this.gvars.immedComm);
      debugLog(this.gvars.currentquestion.commentaryitems);
      return (
        this.gvars.currentquestion.step == 'IMMEDIATE' &&
        this.gvars.immedComm == true
      );
    },

    isText1Exists() {
      return Boolean(this.gvars.currentquestion.text1);
    },

    isText2Exists() {
      return Boolean(this.gvars.currentquestion.text2);
    },

    isText3Exists() {
      return Boolean(this.gvars.currentquestion.text3);
    },

    isText4Exists() {
      return Boolean(this.gvars.currentquestion.text4);
    },

    isHintEnabled() {
      return Boolean(this.gvars.hintEnabled);
    },

    isHintDisabled() {
      return !this.gvars.hintEnabled;
    },

    enableHint: function () {
      this.gvars.hintEnabled = true;
    },

    disableHint: function () {
      this.gvars.hintEnabled = false;
    },

    isTrialMessageExists() {
      return Boolean(this.gvars.trialMessage);
    },

    setTrialMessage: function (msg) {
      this.gvars.trialMessage = msg;
    },

    clearTrialMessage: function () {
      this.gvars.trialMessage = '';
    },

    isSystemMessageExists() {
      return Boolean(this.gvars.systemMessage);
    },

    clearSystemMessage: function () {
      this.gvars.systemMessage = '';
    },

    setSystemMessage: function (msg) {
      this.gvars.systemMessage = msg;
    },

    isRightAnswer(index) {
      debugLog('rightanswer:' + this.gvars.currentquestion.rightanswer);
      debugLog('index:' + index);
      return this.gvars.currentquestion.rightanswer == index + 1;
    },

    // index が正解番号だった場合は正解に該当する色名を返す
    getRightColor(index) {
      if (this.gvars.currentquestion.rightanswer == index + 1) {
        return 'blue lighten-4';
      } else return 'white';
    },

    getCoefficientWidth: function (w) {
      const coefficient = 0.8;
      if (w > this.$vuetify.breakpoint.width * coefficient) {
        return this.$vuetify.breakpoint.width * coefficient;
      } else {
        return w;
      }
    },

    getCoefficientHeight: function (w, h) {
      const ws = this.getCoefficientWidth(w);
      return (h * ws) / w;
    },

    getImageUrlCommonMethod: function (imgfile) {
      const url = process.env.VUE_APP_IMAGEBASE + '/' + imgfile;
      return url;
    },

    loadImageCommon: async function (src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
        // console.log('loadImage:', src);
      });
    },

    loadImageSizeCommon: async function (src) {
      // console.log('loadImageSize:', src);
      // promise then/catch
      try {
        const res = await this.loadImageCommon(src);
        // console.log('res:', res.width, res.height);
        return res;
      } catch (e) {
        // console.log('onload error', e);
      }
    },

    // 文字列に含まれる <<PICT:.....:...> を検索し分離して basetext,imgfile, imgwidth などのオブジェクトとして返す
    splitTextAndImage: async function (str) {
      if (!str) {
        return { exists: false };
      } // v-if="obj.exists" で存在有無判定が可能

      str = str ? str : '';
      let basetext = str.replace(/^OPT[0-9]:/, '');
      const s = str.match(/<<PICT:(.*\..*):(.*)>>/);
      const imgfile = s ? s[1] : '';
      const imgcaption = s ? s[2] : '';
      basetext = basetext.replace(/<<PICT:(.*\..*):(.*)>>/, '');
      //      basetext = basetext.replace(/\n/g,'<br />');
      let imgwidth = '';
      let imgheight = '';
      if (imgfile) {
        const res = await this.loadImageSizeCommon(
          this.getImageUrlCommonMethod(imgfile)
        );
        imgwidth = res.width;
        imgheight = res.height;
      }

      const t = str.match(/<<HREF:(.*\..*):(.*)>>/);
      const hrefurl = t ? t[1] : '';
      const hrefcaption = t ? t[2] : '';
      basetext = basetext.replace(/<<HREF:(.*\..*):(.*)>>/, '');
      //      basetext = basetext.replace(/\n/g,'<br />');

      return {
        exists: true,
        basetext: basetext,
        imgfile: imgfile,
        imgcaption: imgcaption,
        imgwidth: imgwidth,
        imgheight: imgheight,
        hrefurl: hrefurl,
        hrefcaption: hrefcaption,
      };
    },

    // 複数行のプレーンテキスト<改行>PICT:画像ファイル名 という形式のデータを解析する
    splitSimpleTextAndImage: async function (str) {
      if (!str) {
        return { exists: false };
      } // v-if="obj.exists" で存在有無判定が可能

      let basetext = str.replace(/^PICT:.*$/m, '');
      const s = str.match(/^PICT:\s*(\S*\..*)$/m);
      const imgfile = s ? s[1] : '';
      let imgwidth = '';
      let imgheight = '';
      let imgurl = '';
      if (imgfile) {
        imgurl = this.getImageUrlCommonMethod(imgfile);
        const res = await this.loadImageSizeCommon(imgurl);
        imgwidth = res.width;
        imgheight = res.height;
      }

      debugLog('splitSimpleTextAndImage:', basetext, imgurl, imgwidth);
      return {
        exists: true,
        basetext: basetext,
        imgurl: imgurl,
        imgfile: imgfile,
        imgwidth: imgwidth,
        imgheight: imgheight,
      };
    },

    optArrayMapper: async function (x) {
      debugLog('optArrayMapper:', x);
      const s = await this.splitTextAndImage(x);
      return s;
    },

    getOptArray: async function (options) {
      const oa = options.match(/^OPT[0-9]:.*/gm); // OPT1: ....  というフォーマットで選択肢を記載
      debugLog(oa);
      let opta = [];
      if (oa) {
        for (const s of oa) {
          const s2 = await this.optArrayMapper(s);
          debugLog(s2);
          opta.push(s2);
        }
        debugLog(opta);
      }
      return opta;
    },

    // 複数行の line から tag を先頭に持つパラメータを1つ読んで返す
    getOneFlagParameter: function (tag, lines) {
      debugLog('1行パラメータの取得', tag, lines);
      // gフラグをつけると match()はグループを返さないのでこの下の正規表現は /m のみ
      const pat = new RegExp('^' + tag + '$', 'm');
      const para = lines.match(pat); // ANS:2 ....  正解番号を記載
      if (!para) {
        debugLog(`${tag}: `, 'パラメータなし');
        return false;
      } else {
        debugLog(`${tag}: `, para);
        return true;
      }
    },

    // 複数行の line から tag を先頭に持つパラメータを1つ読んで返す
    // パラメータがない場合は空文字列
    getOneTextParameter: function (tag, lines) {
      // debugLog('1行パラメータの取得', tag, lines);
      // gフラグをつけると match()はグループを返さないのでこの下の正規表現は /m のみ
      const pat = new RegExp('^' + tag + ':(.*)$', 'm');
      const para = lines.match(pat); // ANS:2 ....  正解番号を記載
      if (!para) {
        //debugLog(`${tag}: `, 'パラメータなし');
        return '';
      } else {
        debugLog(`${tag}: `, para[1]);
        return para[1];
      }
    },

    getCorrectAnswer: function (options) {
      debugLog('正解番号の解析', options);
      // gフラグをつけると match()はグループを返さないのでこの下の正規表現は /m のみ
      const ans = options.match(/^ANS:\s*([0-9])\s*/m); // ANS:2 ....  正解番号を記載
      if (!ans) {
        debugLog('選択肢には正解番号が必要です');
        return 99;
      } else {
        debugLog('正解番号:', ans[1]);
        return ans[1];
      }
    },

    fieldArrayMapper: async function (x) {
      debugLog('fieldArrayMapper:', x);
      const s = await this.splitFieldnameAndLabel(x);
      return s;
    },

    getFieldArray: async function (options) {
      const oa = options.match(/^FIELD[0-9]:.*/gm); // FIELD#: ....  というフォーマットで入力項目を記載
      debugLog(oa);
      let opta = [];
      if (oa) {
        for (const s of oa) {
          const s2 = await this.fieldArrayMapper(s);
          debugLog(s2);
          opta.push(s2);
        }
        debugLog(opta);
      }
      return opta;
    },

    // 文字列に含まれる <<FIELD:.....:...> を検索し分離して
    splitFieldnameAndLabel: async function (str) {
      str = str ? str : '';
      str = str.replace(/^FIELD[0-9]:/, '');
      const s = str.match(/(.*) ?## ?(.*) ?## ?(.*)/);
      const fieldname = s ? s[1] : '';
      const fieldcaption = s ? s[2] : '';
      const fieldanswer = s ? s[3] : '';
      return {
        fieldname: fieldname,
        fieldcaption: fieldcaption,
        fieldanswer: fieldanswer,
        fieldvalue: '',
      };
    },

    backwardHistory: function () {
      this.$router.go(-1);
    },

    toTopPage: function () {
      debugLog('toTopPage');
      // mode/modestring のデータは本来 '/modestring' の形式だが、
      // root に modestring を付加するときは / を除去してやらないといけない
      // '' （空文字列) の場合はそのまま。
      let modestring = this.$store.getters['mode/modestring'];
      modestring = modestring ? modestring.replace('/', '') : '';
      const path = this.$route.path;
      const newpath = '/' + modestring;
      debugLog('path:', path);
      debugLog('newpath:', newpath);
      if (path != newpath) {
        this.$router.push(newpath);
      }
    },

    toMailformPage: function () {
      let modestring = this.$store.getters['mode/modestring'];
      modestring = modestring ? modestring : '';
      const path = this.$route.path;
      debugLog('toMailformPage' + ':' + path);
      // 既にメールフォームページにいるのでなければ、遷移する
      const pattern = /^\/contact/;
      if (!pattern.test(path)) {
        this.$router.push('/contact/' + modestring);
      }
    },

    toIdeacraftOfficial: function () {
      window.open('http://ideacraft.jp', '_blank');
    },

    convertNewlineToBR: function (text) {
      return text.replace(/\n/g, '<br />');
    },

    // 感想を送信する
    acceptImpression: async function () {
      const mailParams = {
        useraddress: this.gvars.note.personEmail, // 送信元アドレス
        // to: req.params.to,          // 送信先 （使わないパラメータ)
        subject: '感想', // 件名
        pname: this.gvars.note.personName, // 氏名
        text: `${this.gvars.note.impression}
          --------------
          usedseconds:${this.gvars.usedseconds}`, // 通常のメール本文
      };

      debugLog(mailParams);

      debugLog('calling /gs/SML/');
      this.gvars.sending = true;
      this.setSystemMessage('送信中....');

      const result = await postRecord('/gs/SML/', mailParams);
      debugLog(result);
      this.gvars.sending = false;
      if (result.resultcode == 'OK') {
        return 'OK';
      } else {
        return 'ERROR';
      }
    },

    // Text Piece の解析
    analyseTextPieceParameters: async function (p) {
      if (p.ptype !== 'TEXT') return;
      const s = await this.splitSimpleTextAndImage(p.value);
      p.basetext = s.basetext.trim();
      p.imgurl = s.imgurl.trim();
      p.imgfile = s.imgfile.trim();
      p.imgwidth = s.imgwidth;
      p.imgheight = s.imgheight;
      debugLog('Text options:', p);
    },

    // Splitter Piece の解析
    analyseSplitterPieceParameters: async function (p) {
      if (p.ptype !== 'SPLITTER') return;
      debugLog('Splitter options:', p);
    },

    // Button型に属すpiece の解析
    analyseButtonPieceParameters: function (p) {
      if (!this.isButtonPiece(p.ptype)) return;

      p.label = this.getOneTextParameter('LABEL', p.value).trim();
      p.id = this.getOneTextParameter('ID', p.value).trim();
      p.note = this.getOneTextParameter('NOTE', p.value).trim();
      p.send = this.getOneFlagParameter('SEND', p.value) ? true : false;
      p.starttimer = this.getOneFlagParameter('STARTTIMER', p.value)
        ? true
        : false;
      p.lappoint = this.getOneFlagParameter('LAPPOINT', p.value) ? true : false;
      p.vanish = this.getOneFlagParameter('VANISH', p.value) ? true : false;
      p.answer = this.getOneFlagParameter('ANSWER', p.value) ? true : false;

      debugLog('Button options:', p);
    },

    // Image piece の解析
    analyseImagePieceParameters: async function (p) {
      if (p.ptype !== 'IMAGE') return;

      const filename = this.getOneTextParameter('PICT', p.value).trim();
      const imgurl = this.getImageUrlCommonMethod(filename);
      const res = await this.loadImageSizeCommon(imgurl);
      p.imgurl = imgurl.trim();
      p.imgwidth = res.width;
      p.imgheight = res.height;
      debugLog('IMAGE file:', p);
    },

    // Choice, Textfield piece の解析
    analyseQuestionPieceParameters: async function (p) {
      if (!this.isInquiryPiece(p.ptype)) {
        return; // 質問pieceでないなら何もしない
      }

      p.code = this.getOneTextParameter('CODE', p.value).trim();
      p.lead = this.getOneTextParameter('LEAD', p.value).trim();
      p.imgfile = this.getOneTextParameter('PICT', p.value).trim();
      debugLog(`p.imgfile = ${p.imgfile}`);
      if (p.imgfile) {
        p.imgurl = this.getImageUrlCommonMethod(p.imgfile).trim();
        debugLog(`p.imgurl = ${p.imgurl}`);
        const res = await this.loadImageSizeCommon(p.imgurl);
        p.imgwidth = res.width;
        p.imgheight = res.height;
        debugLog(`width, height = ${res.width},${res.height}`);
      } else {
        debugLog('No image file is specified');
        p.imgurl = null;
        p.imgwidth = null;
        p.imgheight = null;
      }

      p.body1 = this.getOneTextParameter('BODY1', p.value).trim();
      p.body2 = this.getOneTextParameter('BODY2', p.value).trim();

      if (p.ptype == 'CHOICE') {
        p.optarray = await this.getOptArray(p.value);
        p.placedanswer = 0;
        p.donecorrectly = false;
        p.CorrectAnswerNum = this.getCorrectAnswer(p.value);
        p.optarray[p.CorrectAnswerNum - 1].isCorrectAnswer = true;
        debugLog('CHOICE:', p);
      } else if (p.ptype == 'TEXTFIELD') {
        p.optarray = await this.getFieldArray(p.value);
        debugLog('TEXTFIELD:', p);
      } else {
        return;
      }
    },

    // Link piece の解析
    analyseLinkPieceParameters: async function (p) {
      if (p.ptype !== 'LINK') return;
      p.label = this.getOneTextParameter('LABEL', p.value).trim();
      p.to = this.getOneTextParameter('TO', p.value).trim();
      p.href = this.getOneTextParameter('HREF', p.value).trim();
      p.target = this.getOneTextParameter('TARGET', p.value).trim();
      p.class = this.getOneTextParameter('CLASS', p.value).trim();
      if (!p.label && p.to) p.label = p.to;
      else if (!p.label && p.href) p.label = p.href;
      debugLog('LINK:', p);
    },

    isButtonPiece: function (ptype) {
      return buttons.includes(ptype);
    },

    isInquiryPiece: function (ptype) {
      return inquiries.includes(ptype);
    },

    isRecordingPiece: function (ptype) {
      return recordingpieces.includes(ptype);
    },

    isEmpty: function (v) {
      if (v === null) return true;
      if (v === undefined) return true;
      if (v === '') return true;
    },
  },
};
