<template>
  <div class="toppage">
    <v-container class="px-0 py-0">
      <v-card
        tile
        outlined
        shaped
        class="ma-0 pa-0"
        style="background-color: white"
        height="40px"
        ><v-card-title class="px-2 py-1"
          >This page is not in use for the time being.</v-card-title
        >
      </v-card>

      <v-card
        tile
        outlined
        shaped
        class="mx-0 mt-8 mb-3 pa-0"
        style="background-color: white"
        height="40px"
        ><v-card-title class="px-2 py-1">作者とのコンタクト</v-card-title>
      </v-card>
      <v-row>
        <v-col>
          <v-btn v-on:click="toMailformPage" block
            >問合・感想・意見を送る</v-btn
          >
        </v-col>
        <v-col>
          <v-btn v-on:click="toIdeacraftOfficial" block
            >アイデアクラフト公式サイト</v-btn
          >
        </v-col>
      </v-row>
      <v-row style="background-color: gray; height: 1px" class="my-10"
        ><v-col></v-col
      ></v-row>
      <!---         権利表記等           ---------->
      <v-row>
        <v-card>
          <v-card-text class="text-caption"
            ><P
              >当サイトは<A href="https://ideacraft.jp" target="_blank"
                >アイデアクラフト</A
              >が運営しています。</P
            >
            <p>
              当サイト上のすべてのコンテンツの著作権は、開米瑞浩が保持しています。（別途著作権表示のあるものを除く）。
            </p>
            <p>
              コンテンツ改善を目的とする統計処理のため、個人情報を含まない解答データを収集しています。
            </p>
            <p>
              感想を送信する最終ページに個人情報（氏名・Email）の欄がありますが入力は任意です。
            </p>
            <p>
              御入力いただいた個人情報は当コンテンツに関する質問への回答にのみ利用し、他の用途には使用しません。
            </p>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
    <!-- ------------- 最下段にsystemMessageを出す ------------- -->
    <system-message :systemMessage="this.gvars.systemMessage" />
  </div>
</template>

// scriptタグ内にスクリプト
<script lang="js">
import Vue from 'vue';

import {getRecords} from '../client/client.js';

import SystemMessage from '@/components/SystemMessage.vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};

export default Vue.extend({
  name: 'Notify1page',

  mixins:[cm],

  components:{
    SystemMessage,
  },

  props:{
    mode:{
      type:String,
      required: false,
      default:'',
    }
  },

  data: () => ({

  }),

  created: function(){
    debugLog('Notify1page Created');
    this.initializeStaticParameters(this.mode);
    this.gvars=this.$store.getters['vars/gv'];

  },

  mounted: async function(){
    debugLog('Notify1page Mounted');
    document.title = this.$route.meta.title + ' | ' + process.env.VUE_APP_SITENAME;
    debugLog(document.title);
  },

  methods: {
    getGstLinkString: function(code){
      return '/gst/' + code +'/' + this.$store.getters['mode/modestring']
    },


    getTestunits: async function(){
        const result = await getRecords('/gs/LTUA/');
        if(result.resultcode != 'OK'){
            debugLog(result);
            this.setSystemMessage("データを取得できません");
            return;
        }

        this.testunits = result.rows;
        debugLog(this.testunits);
    },

    setSystemMessage: function(msg){
      this.systemMessage = msg;
    },

    clearSystemMessage: function(){
      this.systemMessage = "";
    },

  },
});
</script>
