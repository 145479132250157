<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          name="sc_title"
          prefix="■ "
          v-model="gvars.scenario.title"
          color="cyan lighten-4"
          class="v-text-field-qtitle"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      ><v-col>{{ gvars.scenario.leadtext }}</v-col></v-row
    >
    <!-- ------------- 区切り線 ------------- -->
    <v-row style="background-color: #00897b; height: 10px" class="my-10"
      ><v-col></v-col
    ></v-row>
  </v-container>
</template>

<script lang="js">

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "ScenarioHeaderPanel",

  mixins:[cm],
  props: {
    startScenarioExecutionEmitter:{
        type: Function,
        required:true,
    },
  },

  data: () => ({
    preguideparams:'',    // preguideparams の代替
  }),

  created: function(){
    debugLog("ScenarioHeaderPanel created");
    this.gvars=this.$store.getters['vars/gv'];
  },

  computed: {
  },

  mounted: function(){
    debugLog("ScenarioHeaderPanel mounted");
    debugLog("title", this.gvars.scenario.title);
    debugLog("frames:", this.gvars.frames);
  },

  methods:{
    // テスト開始ボタンのハンドラー
    triggerStartScenario: function(){
      debugLog("triggerStartScenario");
      this.startScenarioExecutionEmitter();
      // 親にemitする
    },

  },
};
</script>
