<template>
  <v-container>
    <v-row>
      <v-col align="center">
        <h1>お疲れさまでした!</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <v-btn
          color="info"
          v-on:click="triggerRestartTestunitSession"
          width="180px"
          >もう一度解く</v-btn
        >
      </v-col>
      <v-col align="center">
        <v-btn color="info" v-on:click="triggerBacktoResultlist" width="180px"
          >前ページへ戻る</v-btn
        >
      </v-col>
      <v-col align="center">
        <v-btn color="info" v-on:click="triggerToTopPage" width="180px"
          >トップページへ</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
import cm from '../mixins/commonMethods';
const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "FarewellPanel",

  mixins:[cm],

  props: {
    restartTestunitSessionEmitter:{
        type: Function,
        required:true,
    },
    backtoResultlistEmitter:{
        type: Function,
        required:true,
    },
  },

  data: () => ({
  }),

  created: function(){
    debugLog("FarewellPanel created");
    this.gvars=this.$store.getters['vars/gv'];
    debugLog("vars/gv.testdata:", this.$store.getters['vars/gv'].testdata);
  },

  computed: {
  },

  mounted: function(){
    debugLog("Farewell mounted");
  },

  methods:{
    triggerRestartTestunitSession: function(){
      debugLog("triggerRestartTestunitSession");
      this.restartTestunitSessionEmitter();
    },
    triggerBacktoResultlist: function(){
      debugLog("triggerBacktoResultlist");
      this.backtoResultlistEmitter();
    },
    triggerToTopPage: function(){
      debugLog("triggerToTopPage");
      this.toTopPage();
    },
  },
};
</script>

<style></style>
