import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

// development mode で動いてますよという警告を console に表示するかどうか
// Vue 3 ではproductionTipは削除されている
Vue.config.productionTip =
  process.env.VUE_APP_PRODUCTIONTIP == 'ON' ? true : false; // trueで表示する

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
