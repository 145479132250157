<template>
  <div class="mailform">
    <v-container>
      <v-card elevation="2" shaped tile>
        <v-container>
          <v-row><v-col>感想・意見・要望連絡フォーム</v-col></v-row>
          <v-row>
            <v-col style="height: 45px"
              ><v-icon color="blue">mdi-help-circle-outline</v-icon
              >社員研修に利用してもかまいませんか？</v-col
            >
          </v-row>
          <v-row>
            <v-col style="height: 45px"
              ><v-icon color="blue">mdi-help-circle-outline</v-icon
              >Webセミナー形式での研修はできますか？</v-col
            >
          </v-row>
          <v-row>
            <v-col style="height: 45px"
              ><v-icon color="blue">mdi-help-circle-outline</v-icon
              >○○の問題については△△という考え方もできませんか？</v-col
            >
          </v-row>
          <v-row>
            <v-col style="height: 45px"
              ><v-icon color="blue">mdi-help-circle-outline</v-icon
              >「（例文つきで）こんな文章を図解するとしたらどんな方法がありますか？」</v-col
            >
          </v-row>
          <v-row
            ><v-col style="height: 45px"
              >などなど、ご感想ご要望等お送りください</v-col
            ></v-row
          >
        </v-container>
      </v-card>
      <v-row>
        <v-col>
          <v-text-field
            name="pname"
            prefix="氏名: "
            v-model="pname"
            color="deep-orange accent-4"
            class="v-text-field-pname"
            hint="(匿名・筆名可):"
            persistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            name="useraddress"
            prefix="Email: "
            v-model="useraddress"
            color="deep-orange accent-4"
            class="v-text-field-address"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ma-2 pa-0">
          <v-textarea
            filled
            name="mailbody"
            label="[本文]"
            v-model="mailbody"
            color="deep-orange accent-4"
            class="v-text-field-mailbody"
          ></v-textarea
        ></v-col>
      </v-row>
      <v-row style="height: 50px" class="ma-0 pa-0" justify="center">
        <v-col cols="1">
          <v-progress-circular indeterminate color="primary" v-if="sending">
          </v-progress-circular>
        </v-col>
        <v-col cols="3"
          ><v-btn
            v-bind:disabled="sending"
            v-on:click="clickSendMail"
            block
            color="info"
            >送信する</v-btn
          >
        </v-col>
        <v-col cols="2"></v-col>
        <v-col cols="3"
          ><v-btn v-on:click="backwardHistory" block color="info">戻る</v-btn>
        </v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row style="height: 60px" class="ma-0 pa-0" justify="center">
        <v-col cols="1"></v-col>
        <v-col cols="3"
          ><div v-if="sendingmessage">{{ sendingmessage }}</div></v-col
        >
        <v-col cols="2"></v-col>
        <v-col cols="2"></v-col>
        <v-col cols="2"></v-col>
      </v-row>
      <v-card>
        <v-card-text
          >お送りいただいた情報は当サイトおよび読解力・図解力問題の改善、お問い合わせの回答にのみ使用します。</v-card-text
        >
      </v-card>
    </v-container>
    <!-- ------------- 最下段にsystemMessageを出す ------------- -->
    <system-message :systemMessage="this.gvars.systemMessage" />
  </div>
</template>

// scriptタグ内にスクリプト
<script lang="js">
import Vue from 'vue';

import {postRecord} from '../client/client.js';

import SystemMessage from '@/components/SystemMessage.vue';

import cm from '@/mixins/commonMethods';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};

export default Vue.extend({
  name: 'Mailform',

  mixins:[cm],

  components:{
    SystemMessage,
  },

  props:{
    mode:{
      type:String,
      required: false,
      default:'',
    }

  },

  data: () => ({
    vbmode:'',
    vomode:'',
    pname:'',         // 氏名
    useraddress:'',   // メールアドレス
    mailbody:'',      // 本文
    sending:false,    // 送信中のプログレスバー表示制御
    sendingmessage: '' // 「送信中」のメッセージ
  }),

  created: function(){
    debugLog('Mailform Created');
    this.initializeStaticParameters(this.mode);
    this.gvars=this.$store.getters['vars/gv'];
  },

  mounted: async function(){
    debugLog('Mailform Mounted')
    document.title = this.$route.meta.title + ' | ' + process.env.VUE_APP_SITENAME;
    debugLog(document.title);
  },

  computed: {
  },

  methods: {
    clickSendMail: async function(){
      const mailParams = {
        // from: 'kai@ideacraft.jp',      // 送信元アドレス
        // to: 'kaimai.mizuhiro@gmail.com',          // 送信先
        // subject: 'メール送信テスト',    // 件名
        // text: 'こちらが本文になります',      // 通常のメール本文

        useraddress: this.useraddress,      // 送信元アドレス
        // to: req.params.to,          // 送信先 （使わないパラメータ)
        subject: '連絡フォーム',    // 件名
        pname: this.pname,           // 氏名
        text: this.mailbody,         // 通常のメール本文
        // html: req.params.bodyhtml,      // HTMLメール
      }

      debugLog(mailParams);

      debugLog('calling /gs/SML/');
      this.sending=true;
      this.sendingmessage="送信中....";

      const result = await postRecord('/gs/SML/', mailParams);
      this.sending=false;
      if(result.resultcode=='OK'){
        this.sendingmessage="送信完了しました";
      }
      else{
        this.sendingmessage="送信できませんでした";
      }
      debugLog(result);
    },

  },
});
</script>
