<template>
  <v-container v-if="notific.sid" class="pa-0">
    <v-card
      v-if="notific.title"
      tile
      outlined
      shaped
      class="ma-0 pa-0"
      style="background-color: white"
      height="40px"
      ><v-card-title class="px-2 py-1">{{ notific.title }}</v-card-title>
    </v-card>
    <v-row
      class="my-0 pl-6 py-0"
      style="background-color: white; font-weight: bold"
      ><v-col v-if="notific.leadtext">{{ notific.leadtext }}</v-col></v-row
    >
    <v-row class="my-0 pl-6 py-0" style="background-color: white"
      ><v-col v-if="notific.bodytext">{{ notific.bodytext }}</v-col></v-row
    >
    <div
      v-for="(piece, index) in notific.frames[0].pieces"
      v-bind:key="index"
      class="my-0 pl-6 py-2"
    >
      <v-row>
        <v-col v-bind:class="piece.class ? piece.class : 'ma-0 px-6 py-3'">
          <piece-block v-if="piece.visible" :piece="piece" />
        </v-col>
      </v-row>
    </div>
    <v-row class="ma-0 pl-4 py-2"><v-col class="ma-0 pa-0"></v-col></v-row>
  </v-container>
</template>

<script lang="js">
import Vue from 'vue';
import PieceBlock from '@/components/PieceBlock.vue';

const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};

export default Vue.extend({
  name: 'NotificationBlockA',
  props:['notific'],

  components:{
    PieceBlock,
  },

  mounted: function(){
    debugLog("NotificationBlockA notific", this.notific);

  },

})
</script>
