const state = {
  verbose: false,
  verifyonly: false,
  modestring: '', // '/modestring' の形式でリンク先に mode を付加する
};

const getters = {
  verbose: (state) => state.verbose,
  verifyonly: (state) => state.verifyonly,
  modestring: (state) => state.modestring,
};

const actions = {
  setVerbose({ commit }, value) {
    commit('setVerbose', value);
  },
  setVerifyonly({ commit }, value) {
    commit('setVerifyonly', value);
  },
  setModestring({ commit }, value) {
    // console.log('actions/setModestring:' + value);
    commit('setModestring', value);
  },
};

const mutations = {
  setVerbose(state, value) {
    // console.log('setVerbose:' + value);
    state.verbose = value;
  },
  setVerifyonly(state, value) {
    // console.log('setVerifyonly:' + value);
    state.verifyonly = value;
  },
  setModestring(state, value) {
    // console.log('mutations/setModestring:' + value);
    state.modestring = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
