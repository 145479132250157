<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          name="tu_title"
          prefix="■ "
          v-model="preguideparams.title"
          color="cyan lighten-4"
          class="v-text-field-qtitle"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <commentary-block
      :commentaryitems="preguideparams.preguideitems"
      :titlelabel="''"
    ></commentary-block>
    <!--    
    <v-row>
      <v-col>
        <div
          v-for="(preguideitem, index) in preguideparams.preguideitems"
          :key="index"
        >
          <div>
            <v-card>
              <v-card-text v-html="preguideitem.basetext"></v-card-text>
              <v-img
                v-if="Boolean(preguideitem.imgfile)"
                v-bind:src="getImageUrlCommonMethod(preguideitem.imgfile)"
                v-bind:height="
                  getCoefficientHeight(
                    preguideitem.imgwidth,
                    preguideitem.imgheight
                  )
                "
                v-bind:width="getCoefficientWidth(preguideitem.imgwidth)"
              ></v-img>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
-->
    <v-row>
      <v-spacer></v-spacer>
      <v-col align="center">
        <v-btn
          color="info"
          v-on:click="triggerStartTesting"
          width="200px"
          height="50px"
          >開始</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row><v-col></v-col><v-col></v-col></v-row>
    <v-row>
      <v-card>
        <v-card-text
          class="text-caption"
          v-bind:style="selectTcs('standardtext')"
          ><P
            >当サイトは<A href="https://ideacraft.jp" target="_blank"
              >アイデアクラフト</A
            >が運営しています。</P
          >
          <p>
            当サイト上のすべてのコンテンツの著作権は、開米瑞浩が保持しています。（別途著作権表示のあるものを除く）。
          </p>
          <p>
            コンテンツ改善を目的とする統計処理のため、個人情報を含まない解答データを収集しています。
          </p>
          <p>
            感想を送信する最終ページに個人情報（氏名・Email）の欄がありますが入力は任意です。
          </p>
          <p>
            御入力いただいた個人情報は当コンテンツに関する質問への回答にのみ利用し、他の用途には使用しません。
          </p>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="js">
import CommentaryBlock from '@/components/CommentaryBlock.vue';

import cm from '@/mixins/commonMethods';


const debugLog = (process.env.VUE_APP_LOG=='ON')? console.log.bind(console) : ()=>{};
// ログを出力したい箇所に記述
// debugLog("test");

export default {
  name: "PreguidePanel",

  components:{
    CommentaryBlock,
  },

  mixins:[cm],
  props: {
    startTestingAquestionEmitter:{
        type: Function,
        required:true,
    },
  },

  data: () => ({
    preguideparams:'',    // preguideparams の代替
  }),

  created: function(){
    debugLog("PreguidePanel created");
    this.gvars=this.$store.getters['vars/gv'];
    debugLog("vars/gv.testdata:", this.$store.getters['vars/gv'].testdata);
  },

  computed: {
  },

  mounted: function(){
    debugLog("Preguide mounted");
    this.preguideparams = this.$store.getters['vars/preguideparams'];
    debugLog("preguideparams", this.preguideparams);
    debugLog("preguideparams.title:", this.preguideparams.title);
    debugLog("vars/preguideparams.title:", this.$store.getters['vars/preguideparams'].title);

  },

  methods:{
    // テスト開始ボタンのハンドラー
    triggerStartTesting: function(){
      debugLog("triggerStartTesting");
      this.startTestingAquestionEmitter();
      // 親にemitする
    },

  },
};
</script>
